<template>
  <div v-if="getIsAuthorized">
    <b-navbar class="navbar">
      <b-navbar-brand tag="h1" class="mb-0"
        ><img width="80" height="80" src="../../assets/img/mdme-logo.png"
      /></b-navbar-brand>
      <div class="d-flex w-100 justify-content-end nav-container">
        <template v-if="!getUserMustVerifyEmail">
          <router-link
            :to="{ name: 'Home' }"
            @click.native="resetAdministratorView"
          >
            <div :class="getNavClass('Home')">
              <template v-if="getUserIsAdministrator">
                Clients
              </template>
              <template v-else>
                Home
              </template>
            </div>
          </router-link>
          <template v-if="getClientInContext">
            <router-link
              v-for="route in clientRoutes"
              :key="route.name"
              :to="{
                name: route.name,
                params: { clientID: getClientInContext.client_id },
              }"
            >
              <div :class="getNavClass(route.name)">
                {{ route.text }}
              </div>
            </router-link>
          </template>
          <div class="disabled" v-else>
            <div
              title="Please select a client"
              class="navbar-item"
              v-for="route in clientRoutes"
              :key="route.name"
            >
              {{ route.text }}
            </div>
          </div>
        </template>
        <template v-else>
          <a @click="logout"><div class="navbar-item">Logout</div></a>
        </template>
      </div>
      <b-nav-item-dropdown id="dropdown-right" right class="avatar-dropdown">
        <template slot="button-content">
          <b-avatar
            variant="primary"
            :text="getInitial(getUser.attributes)"
          ></b-avatar>
        </template>
        <div style="user-select: none; text-align: center;" class="assigned-to">
          {{ getUser.attributes.email }}
        </div>
        <router-link class="dropdown-item" :to="{ name: 'UserManagement' }"
          >Account Management</router-link
        >
        <template v-if="getClientInContext">
          <router-link
            class="dropdown-item"
            :to="{
              name: 'ClientInfo',
              params: { clientID: getClientInContext.client_id },
            }"
            v-if="getUserIsManager || getUserIsClientAdmin"
            >Client Account Settings</router-link
          >
        </template>
        <a class="dropdown-item" @click="logout">Logout</a>
      </b-nav-item-dropdown>
      <NotificationSystem
        class="notifications my-auto"
        v-if="getUserIsManager || getUserIsClientAdmin"
      />
    </b-navbar>

    <template v-if="!getUserMustVerifyEmail">
      <div class="d-flex justify-content-between">
        <b-breadcrumb class="w-75 h-25 breadcrumb">
          <b-breadcrumb-item
            v-for="(breadcrumb, i) in breadcrumbs"
            :key="i"
            :active="i == breadcrumbs.length - 1 ? true : false"
          >
            <router-link
              :active="i == breadcrumbs.length - 1 ? true : false"
              :to="breadcrumb.to"
              @click.native="
                () => {
                  if (breadcrumb.action) {
                    breadcrumb.action();
                  }
                  return true;
                }
              "
            >
              {{ breadcrumb.text }}
            </router-link>
          </b-breadcrumb-item>
        </b-breadcrumb>
        <div class="m-2" v-if="getClientInContext">
          <div v-if="getClientInContext.logo" class="nav-client-logo">
            <img height="auto" width="150" :src="getClientInContext.logo" />
          </div>
          <p class="text-muted"></p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NotificationSystem from "@/components/manager/NotificationSystem";

export default {
  components: { NotificationSystem },
  data() {
    return {
      userDisplay: null,
      breadcrumbs: [],
    };
  },
  created() {
    this.$bus.$on("breadcrumbData", ($event) => {
      let breadcrumbs = this.getInitialBreadcrumbs();

      if ($event != null && $event != []) {
        for (let i in $event) {
          breadcrumbs.push($event[i]);
        }
      }
      this.breadcrumbs = breadcrumbs;
    });
    this.$bus.$on("breadcrumbPush", ($event) => {
      if (!Array.isArray(this.breadcrumbs)) {
        return false;
      }
      if ($event) {
        if (Array.isArray($event)) {
          // [] == false so this array must have contents
          this.breadcrumbs.push(...$event);
        } else if (typeof $event == "object") {
          this.breadcrumbs.push($event);
        }
      }
    });
  },
  async beforeDestroy() {
    this.$bus.$off("breadcrumbData");
    this.$bus.$off("breadcrumbPush");
  },
  computed: {
    ...mapGetters([
      "getUserMustVerifyEmail",
      "getIsAuthorized",
      "getUserIsClientAdmin",
      "getUserIsAdministrator",
      "getUserIsManager",
      "getUser",
      "getClientInContext",
    ]),
    clientRoutes() {
      let clientRoutes = [];

      if (this.getUserIsAdministrator) {
        clientRoutes.push({
          text: "Current Client",
          name: "ClientInfo",
        });
      }

      clientRoutes = clientRoutes.concat([
        {
          text: "Workflows",
          name: "ClientWorkflowOverview",
        },
        {
          text: "Timelines",
          name: "ClientTimelines",
        },
        {
          text: "Reporting",
          name: "ClientReporting",
        },
        {
          text: "Consumers",
          name: "ClientConsumers",
        },
      ]);

      if (this.getUserIsAdministrator || this.getUserIsClientAdmin) {
        clientRoutes.push({
          text: "Notification Templates",
          name: "ClientNotificationTemplateList",
        });
      }

      return clientRoutes;
    },
  },
  methods: {
    getInitialBreadcrumbs() {
      if (this.getUserIsAdministrator) {
        let breadcrumbs = [
          {
            text: "Client List",
            to: {
              name: "Home",
            },
            action: this.resetAdministratorView,
          },
        ];
        if (this.getClientInContext) {
          breadcrumbs.push({
            text:
              this.getClientInContext.client_name ||
              `Client #${this.getClientInContext.client_id} Info`,
            to: {
              name: "ClientInfo",
              params: {
                clientID: this.getClientInContext.client_id,
              },
            },
          });
        }
        return breadcrumbs;
      } else if (this.getUserIsClientAdmin || this.getUserIsManager) {
        return [
          {
            text: "Dashboard",
            to: {
              name: "Home",
              params: { component: "ManagerDashboard" },
            },
          },
        ];
      }
      return [];
    },
    ...mapActions(["fetchUser"]),
    async resetAdministratorView() {
      if (this.getUserIsAdministrator) {
        try {
          await this.$store.commit("setResetClientInContext");
        } catch {
          return false;
        }
      }
      return true;
    },
    async logout() {
      await this.$store.dispatch("logout");
    },
    getInitial(userAttr) {
      if (!userAttr) return "U";

      const { name, email } = userAttr;

      if (name) return name.slice(0, 1);
      if (email) return email.slice(0, 1);
    },
    isActiveRoute(routeName) {
      return this.$route.name === routeName;
    },
    getNavClass(routeName) {
      const active = this.isActiveRoute(routeName);

      return `navbar-item ${active ? "active" : ""}`;
    },
  },
};
</script>
