<template>
  <div class="widget-container">
    <div v-if="showDataBag" class="w-75 mx-auto mb-3 mt-2">
      <b-form-select :options="options" v-model="data"></b-form-select>
      <button class="btn-blue mt-2 w-100" @click="insertItem(data)">Add</button>
    </div>
    <div id="toolbar">
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <button class="ql-strike"></button>
      <button class="ql-blockquote"></button>
      <select class="ql-color">
        <option value="rgb(0, 0, 0)" />
        <option value="rgb(230, 0, 0)" />
        <option value="rgb(255, 153, 0)" />
        <option value="rgb(255, 255, 0)" />
        <option value="rgb(0, 138, 0)" />
        <option value="rgb(0, 102, 204)" />
        <option value="rgb(153, 51, 255)" />
        <option value="rgb(255, 255, 255)" />
        <option value="rgb(250, 204, 204)" />
        <option value="rgb(255, 235, 204)" />
        <option value="rgb(204, 224, 245)" />
        <option value="rgb(235, 214, 255)" />
        <option value="rgb(187, 187, 187)" />
        <option value="rgb(102, 185, 102)" />
      </select>
      <!--<select class="ql-align"></select>-->
      <button class="ql-link"></button>
      <button id="custom-button" @click="showDataBag = true">
        <i class="fas fa-user-plus"></i>
      </button>
    </div>
    <div id="editor">
      <p>
        In this box you can add some text, <strong>edit the format</strong>, or
        choose a different
        <strong style="color: rgb(153, 51, 255)">color</strong>, or insert a
        variable by clicking on the person icon...
      </p>
    </div>

    <button class="btn-blue mt-2" @click="submitText" v-if="!saved">
      Save Text
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Quill from "quill";

export default {
  props: ["index", "templateMeta"],
  data() {
    return {
      quill: null,
      preview: "",
      data: null,
      showDataBag: false,
      options: [
        { value: null, text: "Please select an available attribute" },
        { value: "consumer_email", text: "Email" },
      ],
      saved: false,
    };
  },
  async mounted() {
    this.quill = new Quill("#editor", {
      theme: "snow",
      modules: {
        toolbar: "#toolbar",
      },
    });

    let self = this;

    this.quill.on("text-change", (delta, oldDelta, source) => {
      this.saved = false;
      if (source == "user") {
        this.preview = self.quill.root.innerHTML;
      }
    });

    if (this.templateMeta && this.templateMeta != []) {
      if (this.templateMeta.data) {
        this.quill.setContents(this.templateMeta.data.quill);
      }
    }

    if (this.getClientInContext) {
      let clientData = await this.$store.dispatch(
        "getDataBagAttributes",
        this.getClientInContext.client_id
      );
      if (clientData && clientData.consumer_meta) {
        let keys = Object.keys(clientData.consumer_meta);
        for (let i in keys) {
          this.options.push({
            value: keys[i],
            text: clientData.consumer_meta[keys[i]].name || keys[i],
          });
        }
      }
    }
  },
  computed: { ...mapGetters(["getClientInContext"]) },
  methods: {
    insertItem(data) {
      const item = " {{ consumer." + data + " }} ";
      let selection = this.quill.getSelection();
      let idx = selection != null ? selection.index : 0;
      this.quill.insertText(idx, item);
      this.showDataBag = false;
    },
    submitText() {
      const data = {
        content: {
          text: this.preview,
        },
        quill: this.quill.getContents(),
        index: this.index,
      };

      this.$bus.$emit("notifyWidgetSaved", data);
      this.saved = true;
    },
  },
};
</script>
