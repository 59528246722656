<template>
  <ContentContainer>
    <template #title>
      <h3>Notification Templates</h3>
    </template>
    <b-container v-if="template != null && templateMeta != null">
      <div v-if="notifyType == 'email'" class="mt-3 mb-2">
        <div class="row justify-content-between w-75 mx-auto">
          <label class="h6">Notification Type:</label>
          <p>{{ Boolean(template.email_subject) ? "E-Mail" : "SMS" }}</p>
        </div>
        <div class="row justify-content-between w-75 mx-auto">
          <label class="h6 w-25 text-left">Template Name:</label>
          <div class="d-flex w-75 justify-content-end">
            <p v-if="!showEditName">
              {{ name || template.notification_template_name }}
            </p>
            <b-form-input
              v-else
              v-model="name"
              :placeholder="
                template.notification_template_name || 'Enter a name'
              "
              size="sm"
            ></b-form-input>
            <p>
              <i
                class="fas fa-pencil-alt back-btn my-auto ml-1"
                @click="showEditName = !showEditName"
              ></i>
            </p>
          </div>
        </div>
        <!-- <b-collapse class="notify-email-container" id="template-name-dropdown">
          <label class="h6">Edit template name: </label>
          <b-form-input
            v-model="name"
            :placeholder="template.notification_template_name || 'Enter a name'"
            size="sm"
          ></b-form-input>
        </b-collapse> -->
        <div class="row justify-content-between w-75 mx-auto">
          <label class="h6 w-25 text-left">Template Subject:</label>
          <div class="d-flex w-75 justify-content-end">
            <p v-if="!showEditSubject">
              {{ subject || template.email_subject }}
            </p>
            <b-form-input
              v-else
              v-model="subject"
              :placeholder="template.email_subject || 'Enter a subject'"
              class="w-100"
              size="sm"
            ></b-form-input>
            <p>
              <i
                class="fas fa-pencil-alt back-btn my-auto ml-1"
                @click="showEditSubject = !showEditSubject"
              ></i>
            </p>
          </div>
        </div>
        <!-- <b-collapse
          class="notify-email-container"
          id="template-subject-dropdown"
        >
          <label class="h6">Subject*: </label>
          <b-form-input
            v-model="subject"
            :placeholder="template.email_subject || 'Enter a subject'"
            size="sm"
          ></b-form-input>
        </b-collapse> -->
        <NotificationTemplateToolbar :templateMeta="templateMeta" />
        <b-card class="notify-widget-container mt-2">
          <draggable
            class="draggable-container"
            v-model="templateMeta"
            group="widgets"
          >
            <div
              id="draggable-element"
              v-for="(widget, i) in templateMeta"
              :key="i"
              class="row"
            >
              <component
                :is="widget.component"
                class="widget-component"
                :index="i"
                :templateMeta="widget"
              />
            </div>
          </draggable>
          <p v-if="!templateMeta || templateMeta.length <= 0">
            Drag an item from the toolbar and drop it here to customize this
            template
          </p>
        </b-card>
      </div>
      <div class="mt-3 mb-2" v-else-if="notifyType == 'sms'">
        <div class="row justify-content-between w-75 mx-auto">
          <label class="h6 w-25 text-left">Template Name:</label>
          <div class="d-flex w-75 justify-content-end">
            <p v-if="!showEditName">
              {{ name || template.notification_template_name }}
            </p>
            <b-form-input
              v-else
              v-model="name"
              :placeholder="
                template.notification_template_name || 'Enter a name'
              "
              size="sm"
            ></b-form-input>
            <p>
              <i
                class="fas fa-pencil-alt back-btn my-auto ml-1"
                @click="showEditName = !showEditName"
              ></i>
            </p>
          </div>
        </div>
        <div class="row w-75 mx-auto mt-3 text-left">
          <label class="w-100 h6" for="textarea-small">
            Message*:
          </label>
          <div class="w-100 mb-2">
            <b-form-select
              class="sm w-50 mr-5"
              :options="smsTemplateData.opts"
              v-model="smsTemplateData.current"
            ></b-form-select>
            <button class="btn-blue w-25" @click="smsInsertDatabagItem()">
              Add
            </button>
          </div>
          <b-form-textarea
            class="w-100"
            id="textarea-small"
            size="sm"
            placeholder="Insert text for text message"
            v-model="smsTemplateData.message"
          >
          </b-form-textarea>
        </div>
      </div>

      <div
        class="row justify-content-between w-75 mx-auto"
        v-if="notifyType != null || templateMeta.length > 0"
      >
        <button class="btn-blue" @click="saveSMSTemplate()">
          Save Template
        </button>
        <button
          class="btn-outline-blue"
          @click="displayPreview"
          v-if="(templateMeta && templateMeta.length > 0) || smsTemplateData.message"
        >
          Preview Template
        </button>
      </div>
    </b-container>

    <ConsumerModal
      :show="showConsumerModal"
      :onClose="
        () => {
          showConsumerModal = false;
        }
      "
      :onContinue="handleContinue"
    />

    <b-modal
      title="Preview Template"
      size="xl"
      v-model="showPreview"
      v-if="templateMeta.length > 0 && selectedConsumer"
      class="mt-2"
      hide-footer
    >
      <div v-for="(meta, i) in templateMeta" :key="i">
        <template v-if="meta.data">
          <div v-if="meta.type == 'text'" class="text-widget">
            <EmailContainer>
              <div
                v-html="insertConsumerData(meta.data.quill.ops[0].insert)"
              ></div>
            </EmailContainer>
          </div>
          <div v-else-if="meta.type == 'plain_text'" class="mt-3">
            <SMSContainer>
              <p>{{ insertConsumerData(meta.data.content.text) }}</p>
            </SMSContainer>
          </div>
          <div v-else-if="meta.type == 'photo'">
            <img
              :src="meta.data.content.url"
              :aria-alt="meta.data.content.alttext || 'Photo'"
              height="auto"
              width="350"
            />
          </div>
          <div v-else-if="meta.type == 'video'">
            <vue-plyr>
              <div class="plyr__video-embed" style="width: 350; height: auto">
                <iframe
                  :src="meta.data.content.url"
                  allowfullscreen
                  allowtransparency
                  allow="autoplay"
                ></iframe>
              </div>
            </vue-plyr>
          </div>
          <div v-else>Unsupported widget {{ meta.type }}</div>
        </template>
        <div v-else>Widget #{{ i + 1 }} has not been setup yet.</div>
      </div>
    </b-modal>
  </ContentContainer>
</template>
<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import NotificationTemplateToolbar from "./NotificationTemplateToolbar";
import TextEdit from "./TextEdit";
import Photo from "./Photo";
import Video from "./Video";
import Vue from "vue";
import VuePlayer from "../VuePlayer";
import ContentContainer from "@/components/templates/ContentContainer";
import EmailContainer from "@/components/templates/EmailContainer";
import SMSContainer from "@/components/templates/SMSContainer";
import ConsumerModal from "@/components/shared/ConsumerModal";
Vue.use(VuePlayer);

export default {
  components: {
    draggable,
    NotificationTemplateToolbar,
    TextEdit,
    Photo,
    Video,
    ContentContainer,
    EmailContainer,
    SMSContainer,
    ConsumerModal,
  },
  data() {
    return {
      notifyType: null,
      template: null,
      templateMeta: [],
      name: null,
      subject: "",
      showPreview: false,
      showEditName: false,
      showEditSubject: false,
      showConsumerModal: false,
      selectedConsumer: null,
      smsTemplateData: {
        opts: [
          { value: null, text: "Please select an available attribute" },
          { value: "consumer_email", text: "Email" },
        ],
        current: null,
        message: "",
      },
    };
  },
  async mounted() {
    if (!this.getClientInContext) {
      const clientID = this.$route.params.clientID;
      const client = await this.$store.dispatch("getClientByID", clientID);

      if (!client)
        return this.$router.replace({ name: "Home" }).catch(() => {});
    }
    let notificationTemplateID = 0;
    try {
      notificationTemplateID = isNaN(this.$attrs?.notificationTemplateID)
        ? 0
        : parseInt(this.$attrs.notificationTemplateID, 10);
    } catch {
      notificationTemplateID = 0;
    }
    if (!notificationTemplateID) {
      this.$store.dispatch("createErrors", "Invalid Notification Template ID");
      return this.$router
        .replace({ name: "NotificationTemplateList" })
        .catch(() => {});
    }

    if (!this.getClientNotificationTemplateData) {
      await this.$store.dispatch("getClientNotificationTemplates", true);
    }
    if (!Array.isArray(this.getClientNotificationTemplateData)) {
      this.$store.dispatch(
        "createErrors",
        "Issue fetching notification templates"
      );
      return this.$router
        .replace({
          name: this.getUserIsAdministrator ? "ClientInfo" : "Home",
        })
        .catch(() => {});
    }
    let notificationTemplate = null;
    for (let i in this.getClientNotificationTemplateData) {
      if (
        this.getClientNotificationTemplateData[i].notification_template_id ==
        notificationTemplateID
      ) {
        notificationTemplate = this.getClientNotificationTemplateData[i];
        break;
      }
    }
    if (!notificationTemplate) {
      this.$store.dispatch("createErrors", "Invalid Notification Template ID");
      return this.$router
        .replace({ name: "NotificationTemplateList" })
        .catch(() => {});
    }
    this.template = notificationTemplate;

    if (this.template.notification_template_meta != null) {
      this.templateMeta = this.template.notification_template_meta;
    }

    if (this.template.email_subject != null) {
      this.notifyType = "email";
    } else {
      this.notifyType = "sms";
      // load in databag
      this.smsTemplateData.message = Array.isArray(this.templateMeta)
        ? this.templateMeta[0]?.data?.content?.text || ""
        : "";
      if (this.getClientInContext.consumer_meta) {
        let keys = Object.keys(this.getClientInContext.consumer_meta);
        for (let i in keys) {
          this.smsTemplateData.opts.push({
            value: keys[i],
            text:
              this.getClientInContext.consumer_meta[keys[i]].name || keys[i],
          });
        }
      }
    }

    await this.$bus.$emit("breadcrumbData", [
      {
        text: "Notification Templates",
        to: {
          name: "ClientNotificationTemplateList",
        },
      },
      {
        text: `Edit ${this.notifyType[0].toUpperCase() +
          this.notifyType.substr(1).toLowerCase()} Notification ${
          this.template.notification_template_name
            ? `"${this.template.notification_template_name}"`
            : `#${notificationTemplateID}`
        }`,
        to: {
          name: "ClientNotificationTemplateEdit",
          params: {
            notificationTemplateID: notificationTemplateID,
          },
        },
      },
    ]);
  },
  created() {
    this.$bus.$on("notifyWidgetSaved", ($event) => {
      if ($event) {
        if (this.templateMeta[$event.index]) {
          this.templateMeta[$event.index].data = $event;
        }
      }
    });
  },
  async beforeDestroy() {
    this.$bus.$off("notifyWidgetSaved");
  },
  computed: {
    ...mapGetters([
      "getClients",
      "getGroups",
      "getClientNotificationTemplateData",
      "getUserIsAdministrator",
      "getClientInContext",
    ]),
  },
  methods: {
    async onPhotoUpload(e) {
      const file = (await e.target.files[0]) || e.dataTransfer.files[0];
      this.photoDataUrl = file;
      const reader = new FileReader();

      reader.onload = (e) => {
        this.photoDataUrl = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    displayPreview() {
      this.showConsumerModal = true;
      this.saveSMSTemplate(false);
    },
    async saveSMSTemplate(notify = true) {
      if (this.notifyType == "sms") {
        if (this.smsTemplateData.message) {
          this.templateMeta = [
            {
              type: "plain_text",
              data: {
                content: {
                  text: this.smsTemplateData.message,
                },
              },
            },
          ];
        } else {
          if (
            this?.templateMeta[0]?.type != "plain_text" ||
            !this?.templateMeta[0]?.data?.content?.text
          ) {
            this.$store.dispatch(
              "createErrors",
              "Text notifications must have a message"
            );
          }
          return false;
        }
      }

      let template = {
        notificationTemplateMeta: this.templateMeta,
        notificationTemplateID: this.template.notification_template_id,
        clientID: this.template.client_id,
      };

      if (this.name != "" && this.name != null) {
        template.notificationTemplateName = this.name;
      }

      if (
        this.notifyType == "email" &&
        this.subject != "" &&
        this.subject != null
      ) {
        template.emailSubject = this.subject;
      }

      let result = await this.$store.dispatch(
        "updateNotificationTemplate",
        template
      );

      if (result && notify) {
        this.$store.dispatch(
          "createAlerts",
          "Notification template has been saved!"
        );
      }
    },
    smsInsertDatabagItem() {
      this.smsTemplateData.message +=
        " {{ consumer." + this.smsTemplateData.current + " }} ";
    },
    insertConsumerData(text) {
      if (this.selectedConsumer) {
        const toReplace = text.match(/({{ .+? }})/gm);

        if (!toReplace) {
          return text;
        }

        let newText = text;

        toReplace.forEach((match) => {
          const key = match.replace(/({{ consumer\.)|( }})/gm, "");

          try {
            const value = this.selectedConsumer.meta[key];

            if (value) {
              newText = newText.replace(match, value);
            } else {
              newText = newText.replace(match, `<${key}>`);
            }
          } catch (err) {
            newText = newText.replace(match, `<${key}>`);
          }
        });

        return newText;
      }

      return text;
    },
    handleContinue(consumer) {
      this.selectedConsumer = consumer;
      this.showConsumerModal = false;
      this.showPreview = true;
    },
  },
};
</script>
