<template>
	<div class="widget-container">
			<div class="row text-left w-75 mx-auto">
				<label class="w-50">Upload your photo:</label>
				<b-form-file @change="onPhotoUpload($event)" accept="image/*" size="sm" id="file-small" class="w-50"></b-form-file>
			</div>
			<div v-if="photoDataUrl" class="col mt-4">
				<img :src="photoDataUrl" height="auto" width="350"/>
			</div>
			<div v-if="!saved">
				<button class="btn-blue w-100 mt-4" @click="uploadPhoto">Upload Photo</button>
			</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: ['index', 'templateMeta'],
	data() {
		return {
			file: null,
			alttext: '',
			photoDataUrl: [],
			saved: false,
		}
	},
	computed: { ...mapGetters(['getClientInContext'])},
	mounted() {
		if (this.templateMeta && this.templateMeta != []) {
			if (this.templateMeta.data && this.templateMeta.data.content) {
				this.photoDataUrl = this.templateMeta.data.content.url || null;
				this.saved = true;
			}
		}
	},
	methods: {
		async onPhotoUpload(e) {
			this.saved = false;
			const file = await e.target.files[0] || e.dataTransfer.files[0];
			this.file = file;
			const reader = new FileReader();

			reader.onload = (e) => {
				this.photoDataUrl = e.target.result;
			};

			reader.readAsDataURL(file);
		},
		async uploadPhoto() {
			if (!this.file && !this.photoDataUrl) {
				return this.$store.dispatch('createErrors', 'Please select a photo to upload.');
			}

			let url;
			if (this.file) {
				const formData = new FormData();
				formData.append('file', this.file);
				formData.append('clientID', this.getClientInContext.client_id);
				formData.append('fileName', this.file.name.trim());

				url = await this.$store.dispatch('uploadFile', {
					clientID: this.getClientInContext.client_id,
					formData: formData
				});

				if (!url || url.error != null) {
					return this.$store.dispatch('createErrors', 'There was an issue uploading your photo. Please make sure it is the correct file type and try again.');
				}
			}
			let data = {
				content: {
					url: url || this.photoDataUrl,
					alttext: this.alttext,
				},
				index: this.index
			};

			this.$bus.$emit('notifyWidgetSaved', data);
			this.saved = true;
		},
	}
}
</script>
