var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getIsAuthorized)?_c('div',[_c('b-navbar',{staticClass:"navbar"},[_c('b-navbar-brand',{staticClass:"mb-0",attrs:{"tag":"h1"}},[_c('img',{attrs:{"width":"80","height":"80","src":require("../../assets/img/mdme-logo.png")}})]),_c('div',{staticClass:"d-flex w-100 justify-content-end nav-container"},[(!_vm.getUserMustVerifyEmail)?[_c('router-link',{attrs:{"to":{ name: 'Home' }},nativeOn:{"click":function($event){return _vm.resetAdministratorView.apply(null, arguments)}}},[_c('div',{class:_vm.getNavClass('Home')},[(_vm.getUserIsAdministrator)?[_vm._v(" Clients ")]:[_vm._v(" Home ")]],2)]),(_vm.getClientInContext)?_vm._l((_vm.clientRoutes),function(route){return _c('router-link',{key:route.name,attrs:{"to":{
              name: route.name,
              params: { clientID: _vm.getClientInContext.client_id },
            }}},[_c('div',{class:_vm.getNavClass(route.name)},[_vm._v(" "+_vm._s(route.text)+" ")])])}):_c('div',{staticClass:"disabled"},_vm._l((_vm.clientRoutes),function(route){return _c('div',{key:route.name,staticClass:"navbar-item",attrs:{"title":"Please select a client"}},[_vm._v(" "+_vm._s(route.text)+" ")])}),0)]:[_c('a',{on:{"click":_vm.logout}},[_c('div',{staticClass:"navbar-item"},[_vm._v("Logout")])])]],2),_c('b-nav-item-dropdown',{staticClass:"avatar-dropdown",attrs:{"id":"dropdown-right","right":""}},[_c('template',{slot:"button-content"},[_c('b-avatar',{attrs:{"variant":"primary","text":_vm.getInitial(_vm.getUser.attributes)}})],1),_c('div',{staticClass:"assigned-to",staticStyle:{"user-select":"none","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.getUser.attributes.email)+" ")]),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ name: 'UserManagement' }}},[_vm._v("Account Management")]),(_vm.getClientInContext)?[(_vm.getUserIsManager || _vm.getUserIsClientAdmin)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
            name: 'ClientInfo',
            params: { clientID: _vm.getClientInContext.client_id },
          }}},[_vm._v("Client Account Settings")]):_vm._e()]:_vm._e(),_c('a',{staticClass:"dropdown-item",on:{"click":_vm.logout}},[_vm._v("Logout")])],2),(_vm.getUserIsManager || _vm.getUserIsClientAdmin)?_c('NotificationSystem',{staticClass:"notifications my-auto"}):_vm._e()],1),(!_vm.getUserMustVerifyEmail)?[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-breadcrumb',{staticClass:"w-75 h-25 breadcrumb"},_vm._l((_vm.breadcrumbs),function(breadcrumb,i){return _c('b-breadcrumb-item',{key:i,attrs:{"active":i == _vm.breadcrumbs.length - 1 ? true : false}},[_c('router-link',{attrs:{"active":i == _vm.breadcrumbs.length - 1 ? true : false,"to":breadcrumb.to},nativeOn:{"click":function($event){return (() => {
                if (breadcrumb.action) {
                  breadcrumb.action();
                }
                return true;
              }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(breadcrumb.text)+" ")])],1)}),1),(_vm.getClientInContext)?_c('div',{staticClass:"m-2"},[(_vm.getClientInContext.logo)?_c('div',{staticClass:"nav-client-logo"},[_c('img',{attrs:{"height":"auto","width":"150","src":_vm.getClientInContext.logo}})]):_vm._e(),_c('p',{staticClass:"text-muted"})]):_vm._e()],1)]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }