<template>
	<div>
		<div class="mt-2 mb-5">
			<component :is="currentComponent"/>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import ClientInstances from '@/components/admin/ClientInstances';
	import UserManagement from '@/components/admin/UserManagement';
	import Reporting from '@/components/admin/Reporting';
	import ClientInfo from '@/components/admin/ClientInfo';
	import NotificationTemplates from '@/components/notification_templates/NotificationTemplates';
	import NotificationTemplateEdit from '@/components/notification_templates/NotificationTemplateEdit';

	export default {
		components: {
			ClientInstances,
			UserManagement,
			Reporting,
			ClientInfo,
			NotificationTemplates,
			NotificationTemplateEdit
		},
		data() {
			return {
				currentComponent: 'ClientInstances',
			};
		},
		computed: {
			...mapGetters(['getUser'])
		},
		mounted() {
			this.$bus.$emit('breadcrumbData', []);
			this.fetchUser;
			if (this.$route.params.component) {
				this.currentComponent = this.$route.params.component
			}
		},
		created() {
			this.$bus.$on('component', ($event) => {
				this.currentComponent = $event;
			})
		},
		async beforeDestroy() {
			this.$bus.$off('component');
		},
		methods: {
			...mapActions(['fetchUser'])
		}
	};
</script>
<style>
@import '../assets/css/main.css';
</style>
