import Vue from 'vue';
import { Auth } from 'aws-amplify';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store/index.js';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
		props: true,
	},
	{
		path: '/client/:clientID',
		name: 'ClientInfo',
		meta: {
			title: 'Info',
		},
		component: () => import('../components/admin/ClientInfo.vue'),
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
	},
	{
		path: '/client/:clientID/reporting',
		name: 'ClientReporting',
		component: () => import('../components/manager/Reporting'),
		meta: {
			title: 'Reporting',
		},
		beforeEnter: async (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
	},
	{
		path: '/client/:clientID/workflows',
		name: 'ClientWorkflows',
		redirect: { name: 'ClientWorkflowOverview' },
		component: () => import('../views/WorkflowDesign.vue'),
		beforeEnter: async (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
		children: [
			{
				path: 'overview',
				name: 'ClientWorkflowOverview',
				meta: {
					title: 'Workflows',
				},
				component: () => import('../components/workflow/WorkflowOverview.vue'),
			},
			{
				path: ':uuid/:version/edit/:groupIdx/:taskIdx',
				name: 'ClientTaskEdit',
				meta: {
					title: 'Workflow Task Editing',
				},
				component: () => import('../components/workflow/tasks/TaskEdit.vue'),
			},
			{
				path: ':uuid/:version/edit',
				name: 'ClientWorkflowEditNew',
				meta: {
					title: 'Workflow Editing',
				},
				redirect: { name: 'ClientWorkflowPreview', replace: true },
			},
			{
				path: ':uuid/:version',
				name: 'ClientWorkflowPreview',
				meta: {
					title: 'Workflow Preview',
				},
				component: () =>
					import('../components/workflow/tasks/TaskOverview.vue'),
			},
		],
		props: true,
	},
	{
		path: '/client/:clientID/timelines',
		name: 'ClientTimelines',
		meta: {
			title: 'Timelines',
		},
		component: () => import('../components/timelines/Timelines.vue'),
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
		props: true,
	},
	{
		path: '/client/:clientID/timelines/:timelineID',
		name: 'ClientTimelineOverview',
		meta: {
			title: 'Timeline Overview',
		},
		component: () => import('../components/timelines/TimelineOverview.vue'),
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
		children: [
			{
				meta: {
					title: 'Timeline Task',
				},
				path: ':taskUUID',
				name: 'ClientManagerTimelineTask',
				component: () =>
					import('../components/timelines/TimelineRenderedTask.vue'),
				beforeEnter: (to, from, next) => {
					routeAuthMiddleware(next, to);
				},
				props: true,
			},
		],
		props: true,
	},
	{
		path: '/client/:clientID/consumers',
		component: () => import('../views/Consumers.vue'),
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
		meta: {
			title: 'Consumers',
		},
		children: [
			{
				path: ':consumerID/activity',
				name: 'ConsumerActivity',
				component: () => import('../components/consumer/ConsumerActivity.vue'),
				props: true
			},
			{
				path: ':actionID?',
				name: 'ClientConsumers',
				component: () => import('../components/consumer/ConsumerOverview.vue'),
				props: true,
			},
		],
		props: true,
	},
	{
		path: '/client/:clientID/notification-templates',
		component: () =>
			import('../components/notification_templates/NotificationTemplates.vue'),
		name: 'ClientNotificationTemplateList',
		meta: {
			title: 'Notification Templates',
		},
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(
				next,
				store.getters.getIsAuthorized &&
					store.getters.getUser &&
					(store.getters.getUserIsClientAdmin ||
						store.getters.getUserIsAdministrator)
			);
		},
	},
	{
		path: '/client/:clientID/notification-templates/:notificationTemplateID',
		component: () =>
			import(
				'../components/notification_templates/NotificationTemplateEdit.vue'
			),
		name: 'ClientNotificationTemplateEdit',
		meta: {
			title: 'Notification Template Editing',
		},
		beforeEnter: async (to, from, next) => {
			routeAuthMiddleware(
				next,
				store.getters.getIsAuthorized &&
					store.getters.getUser &&
					(store.getters.getUserIsClientAdmin ||
						store.getters.getUserIsAdministrator)
			);
		},
		props: true,
	},
	{
		path: '/reporting',
		name: 'Reporting',
		component: () => import('../components/manager/Reporting'),
		meta: {
			title: 'Reporting',
		},
		beforeEnter: async (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
	},
	{
		path: '/workflows',
		name: 'Workflows',
		redirect: { name: 'WorkflowOverview' },
		component: () => import('../views/WorkflowDesign.vue'),
		beforeEnter: async (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
		children: [
			{
				path: 'overview',
				name: 'WorkflowOverview',
				meta: {
					title: 'Workflows',
				},
				component: () => import('../components/workflow/WorkflowOverview.vue'),
			},
			{
				path: ':uuid/:version/edit/:groupIdx/:taskIdx',
				name: 'TaskEdit',
				meta: {
					title: 'Workflow Task Editing',
				},
				component: () => import('../components/workflow/tasks/TaskEdit.vue'),
			},
			{
				path: ':uuid/:version',
				name: 'WorkflowEditNew',
				meta: {
					title: 'Workflow Editing',
				},
				component: () =>
					import('../components/workflow/tasks/TaskOverview.vue'),
			},
			// {
			//   path: ":uuid/:version",
			//   name: "WorkflowPreview",
			//   meta: {
			//     title: "Workflow Preview",
			//   },
			//   component: () => import("../components/workflow/WorkflowPreview.vue"),
			// },
		],
		props: true,
	},
	{
		path: '/timelines',
		name: 'Timelines',
		meta: {
			title: 'Timelines',
		},
		component: () => import('../components/timelines/Timelines.vue'),
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
		props: true,
	},
	{
		path: '/timelines/:timelineID',
		name: 'TimelineOverview',
		meta: {
			title: 'Timeline Overview',
		},
		component: () => import('../components/timelines/TimelineOverview.vue'),
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
		children: [
			{
				meta: {
					title: 'Timeline Task',
				},
				path: ':taskUUID',
				name: 'ManagerTimelineTask',
				component: () =>
					import('../components/timelines/TimelineRenderedTask.vue'),
				beforeEnter: (to, from, next) => {
					routeAuthMiddleware(next, to);
				},
				props: true,
			},
		],
		props: true,
	},
	{
		path: '/consumers/:actionID?',
		component: () => import('../views/Consumers.vue'),
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
		meta: {
			title: 'Consumers',
		},
		children: [
			{
				path: '',
				name: 'Consumers',
				component: () => import('../components/consumer/ConsumerOverview.vue'),
				props: true,
			},
		],
		props: true,
	},
	{
		path: '/account-management',
		meta: {
			title: 'Account Management',
		},
		name: 'UserManagement',
		component: () => import('../components/admin/UserManagement.vue'),
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(next, to);
		},
	},
	{
		path: '/dashboard/:clientID/:consumerID/:timelineID/:taskUUID',
		component: () => import('../consumer_ui/ConsumerInterface.vue'),
		props: true,
	},
	{
		path: '/unsubscribe/:clientID/:consumerID/:timelineID/:taskUUID',
		component: () => import('../consumer_ui/ConsumerUnsubscribe.vue'),
		props: true,
	},
	{
		path: '/login',
		name: 'Login',
		meta: {
			title: 'Login',
		},
		component: () => import('../components/auth/Login.vue'),
		props: true,
	},
	{
		path: '/forgot-password',
		meta: {
			title: 'Forgotten Password',
		},
		component: () => import('../components/auth/ForgotPassword.vue'),
	},
	{
		path: '/reset-password',
		meta: {
			title: 'Reset Password',
		},
		component: () => import('../components/auth/ResetPassword.vue'),
	},
	{
		path: '/add-client',
		name: 'AddClient',
		meta: {
			title: 'Add New Client',
		},
		component: () => import('../components/admin/actions/AddClient.vue'),
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(
				next,
				store.getters.getIsAuthorized &&
					store.getters.getUser &&
					store.getters.getUserIsAdministrator
			);
		},
	},
	{
		path: '/notification-templates/:notificationTemplateID',
		component: () =>
			import(
				'../components/notification_templates/NotificationTemplateEdit.vue'
			),
		name: 'NotificationTemplateEdit',
		meta: {
			title: 'Notification Template Editing',
		},
		beforeEnter: async (to, from, next) => {
			routeAuthMiddleware(
				next,
				store.getters.getIsAuthorized &&
					store.getters.getUser &&
					(store.getters.getUserIsClientAdmin ||
						store.getters.getUserIsAdministrator)
			);
		},
		props: true,
	},
	{
		path: '/notification-templates',
		component: () =>
			import('../components/notification_templates/NotificationTemplates.vue'),
		name: 'NotificationTemplateList',
		meta: {
			title: 'Notification Templates',
		},
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(
				next,
				store.getters.getIsAuthorized &&
					store.getters.getUser &&
					(store.getters.getUserIsClientAdmin ||
						store.getters.getUserIsAdministrator)
			);
		},
	},
	{
		path: '/add-user',
		name: 'AddUser',
		component: () => import('../components/admin/actions/AddUser.vue'),
		meta: {
			title: 'Add User',
		},
		beforeEnter: (to, from, next) => {
			routeAuthMiddleware(
				next,
				store.getters.getIsAuthorized &&
					store.getters.getUser &&
					(store.getters.getUserIsClientAdmin ||
						store.getters.getUserIsAdministrator)
			);
		},
	},
	{
		path: '/permission-denied',
		component: () => import('../views/SecurityMessage.vue'),
	},
	{
		path: '/validate-email',
		component: () => import('../components/auth/ValidateEmail.vue'),
		beforeEnter: async (to, from, next) => {
			// special flow: must be authorized.
			let user = await verifyUserAuth();
			if (user && !store.getters.getUserMustVerifyEmail) {
				let redirect = '/';
				// TODO: Leave this commented out, once pages and hard linking properly work, uncomment
				/*if (
					to &&
					to.query &&
					(typeof to.query.to) == 'string' &&
					to.query.to.length > 0 &&
					to.query.to[0] == '/' &&
					!to.query.to.startsWith('/validate-email')
				) {
					redirect = to.query.to;
				} else if (from && from.path && from.path != '/login' && from.path != '/validate-email') {
					redirect = from.fullPath;
				}*/
				return next({ path: redirect });
			} else if (!user) {
				return next({ path: '/login' });
			}
			// authorized && email not verified. just continue
			return next();
		},
	},
	{
		// 404 routing back to landing page
		path: '*',
		beforeEnter: async (to, from, next) => {
			if (await verifyUserAuth()) {
				next({ path: '/' });
			} else {
				next({ path: '/login' });
			}
		},
	},
];

/**
 * Verifies that a user can access a route, and directs if needed.
 * @param {function} next
 */
const routeAuthMiddleware = async (next, to, additionalAuth) => {
	let user = await verifyUserAuth();
	if (!user) {
		return next({ path: '/login' });
	}
	if (additionalAuth !== undefined && !additionalAuth) {
		// "Path isn't for you, send away"
		return next({ path: '/' });
	}
	//make sure user's email is validated.
	if (store.getters.getUserMustVerifyEmail) {
		// store where this was redirected from.
		let toParam =
			to && to.fullPath ? '?to=' + encodeURIComponent(to.fullPath) : '';
		return next({ path: '/validate-email' + toParam });
	}
	next();
};

const verifyUserAuth = async () => {
	if (
		store.getters.getUser &&
		store.getters.getIsAuthorized &&
		store.getters['getGroups'].length > 0
	) {
		return store.getters.getUser;
	}

	return await Auth.currentAuthenticatedUser()
		.then(async (user) => {
			let userGroups =
				user.signInUserSession.accessToken.payload['cognito:groups'];
			let group =
				Boolean(user.attributes['custom:ClientName']) &&
				typeof user.attributes['custom:ClientName'] == 'string'
					? [user.attributes['custom:ClientName']]
					: [];
			if (userGroups && userGroups.indexOf('Admin') > -1) {
				await store.dispatch('fetchUser');
			} else if (group && group != []) {
				await store.dispatch('fetchUser', group);
			}
			return store.getters.getUser;
		})
		.catch(() => {
			return false;
		});
};

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

const DEFAULT_TITLE = 'MDME';
router.afterEach((to, from) => {
	Vue.nextTick(() => {
		if (to.meta?.title) {
			document.title =
				(store?.getters?.getClientInContext?.client_name || DEFAULT_TITLE) +
				' ' +
				to.meta.title;
		} else {
			document.title = DEFAULT_TITLE;
		}
	});
});

export default router;
