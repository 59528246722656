import Vue from 'vue';
import App from './App.vue';
import Amplify, { Storage } from 'aws-amplify';
import aws_exports from './aws-exports';
import { BootstrapVue } from 'bootstrap-vue';
// import VueNotification from '@mathieustan/vue-notification';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import router from './router';
import './assets/css/main.css';
import store from './store';

Amplify.configure(aws_exports);
// potential TODO: migration to public/private prefixes for uploads?
Storage.configure({
	customPrefix: {
		public: ''
	}
});
store.state.s3_bucket = aws_exports.aws_user_files_s3_bucket;
store.state.s3_region = aws_exports.aws_user_files_s3_bucket_region;
Vue.use(BootstrapVue);
Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();
// Vue.use(VueNotification, {
// 		breakpoints: {
// 			0: {
// 				bottom: true,
// 			},
// 			480: {
// 				top: true,
// 				right: true,
// 			},
// 		}
// 	}
// );
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
