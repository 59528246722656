<template>
  <b-modal v-model="showModal" @hidden="handleClose" title="Choose a Consumer">
    <div class="text-center m-4" v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else class="dropdown mr-3">
      <a
        v-if="displayConsumer"
        class="btn btn-light dropdown-toggle"
        ref="consumer_modal_dropdown"
        href="#"
        :disabled="loading"
        role="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        >Proceed with {{ displayConsumer.consumer_email }}</a
      >

      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <template v-if="displayConsumers && displayConsumers.length > 1">
          <li v-for="consumer in displayConsumers" :key="consumer.id">
            <a
              :disabled="loading"
              class="dropdown-item"
              @click="() => setActiveConsumer(consumer)"
              >{{ consumer.consumer_email }}</a
            >
          </li>
        </template>
      </ul>
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <button
          type="button"
          class="btn-outline-blue"
          @click.stop.prevent="handleClose"
        >
          Close
        </button>
        <button class="btn-blue" @click.stop.prevent="handleContinue">
          Continue
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      consumers: "getClientConsumerData",
      getClientInContext: "getClientInContext",
    }),
    displayConsumers() {
      if (!this.consumers?.length)
        return [
          {
            consumer_email: "Default consumer",
            id: "test",
          },
        ];
      else return this.consumers;
    },
    displayConsumer() {
      return this.activeConsumer ?? this.displayConsumers[0] ?? null;
    },
  },
  data() {
    return {
      showModal: this.show,
      activeConsumer: null,
      loading: true,
    };
  },
  async mounted() {
    if (!this.getClientInContext) {
      const clientID = this.$route.params?.clientID;
      await this.$store.dispatch("getClientByID", clientID);
    }

    if (!this.consumers || this.consumers.length === 0) {
      await this.$store.dispatch("getClientConsumers", {});
      this.activeConsumer = this.consumers[0];
    }
    this.loading = false;
  },
  props: ["show", "onClose", "onContinue"],
  methods: {
    handleClose() {
      this.showModal = false;
      this.onClose();
    },
    setActiveConsumer(consumer) {
      this.activeConsumer = consumer;
    },
    handleContinue() {
      if (this.displayConsumer) {
        if (this.onContinue) {
          this.onContinue(this.displayConsumer);
        } else {
          this.$bus.$emit("consumerSelected", this.displayConsumer);
        }
      }
    },
  },
  watch: {
    show(val) {
      this.showModal = val;
    },
  },
};
</script>
