<template>
  <ContentContainer>
    <template #title>
      <h3>Notification Templates</h3>
    </template>
    <template #header>
      <button class="btn-blue" v-b-modal.template-dropdown>
        Create new template
      </button>
    </template>
    <div v-if="!loading && !getClientInContext">
      <p>Which client templates would you like to view?</p>
      <b-form-select
        v-model="selected"
        :options="clientOptions"
      ></b-form-select>
      <button class="btn-sm btn-blue w-100 mt-2" @click.prevent="setClient">
        Submit
      </button>
    </div>
    <div class="text-center m-4" v-else-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <b-modal
        id="template-dropdown"
        block
        class="w-100 text-left"
        variant="light"
        title="Create new template"
      >
        <label>Add a name for this template:</label>
        <input
          class="form-control form-control-sm"
          placeholder="Enter a template name"
          v-model="name"
        />
        <label class="mt-2">Choose notification type:</label>
        <b-form-select v-model="notifyType" size="sm">
          <b-form-select-option :value="null"
            >Select an option...</b-form-select-option
          >
          <b-form-select-option value="email">E-mail</b-form-select-option>
          <b-form-select-option value="sms">Text Message</b-form-select-option>
        </b-form-select>
        <label class="mt-2" v-if="notifyType == 'email'"
          >Add a subject for this notification:</label
        >
        <input
          class="form-control form-control-sm"
          placeholder="Enter a subject"
          v-model="subject"
          v-if="notifyType == 'email'"
        />
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-between">
            <button
              type="button"
              class="btn-outline-blue"
              @click.stop.prevent="
                (e) => {
                  $bvModal.hide('template-dropdown');
                }
              "
            >
              Close
            </button>
            <button type="submit" class="btn-blue" @click="createTemplate">
              Create Template
            </button>
          </div>
        </template>
      </b-modal>
      <div class="display-table clickable">
        <b-card
          v-for="(template, i) in notificationTemplates"
          :key="i"
          @click.prevent="editTemplate(template)"
        >
          <b-row class="display-table-row">
            <b-col>
              <template v-if="template.email_subject === null">
                <i class="fas fa-mobile-alt" title="SMS"></i>
              </template>
              <template v-else>
                <i class="fas fa-envelope" title="E-mail"></i>
              </template>
              {{ template.notification_template_name }}
            </b-col>
            <b-col>{{
              template.email_subject == null ? "SMS" : "E-mail"
            }}</b-col>
            <b-col class="assigned-to"
              >created {{ convertDate(template.created_datetime) }}</b-col
            >
          </b-row>
        </b-card>
      </div>
    </div>
  </ContentContainer>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import ContentContainer from "@/components/templates/ContentContainer";

export default {
  components: { ContentContainer },
  data() {
    return {
      loading: false,
      templateMeta: [],
      notificationTemplates: [],
      selected: null,
      clientOptions: [{ value: null, text: "Please select a client" }],
      name: null,
      notifyType: null,
      subject: null,
    };
  },
  async mounted() {
    this.loading = true;

    if (!this.getClientInContext) {
      const clientID = this.$route.params.clientID;
      const client = await this.$store.dispatch("getClientByID", clientID);

      if (!client) {
        this.loading = false;
        return this.$router.replace({ name: "Home" }).catch(() => {});
      }
    }

    if (this.getClientInContext != null) {
      try {
        await this.$bus.$emit("breadcrumbData", [
          {
            text: "Notification Templates",
            to: {
              name: "ClientNotificationTemplateList",
            },
          },
        ]);
        await this.$store.dispatch("getClientNotificationTemplates", true);
        if (this.getClientNotificationTemplateData) {
          this.notificationTemplates = this.getClientNotificationTemplateData;
        }
      } finally {
        this.loading = false;
      }
    } else {
      return this.$router.replace({ name: "Home" }).catch(() => {});
    }
  },
  computed: {
    ...mapGetters([
      "getClients",
      "getClientInContext",
      "getClientNotificationTemplateData",
      "getUserIsAdministrator",
    ]),
  },
  methods: {
    async createTemplate() {
      this.$bvModal.hide("template-dropdown");

      if (!this.getClientInContext) {
        return false;
      }
      if (!this.name) {
        return this.$store.dispatch(
          "createErrors",
          "Please add a name to this notification template."
        );
      }

      if (!this.notifyType) {
        return this.$store.dispatch(
          "createErrors",
          "Please select a notification type."
        );
      }

      if (this.notifyType == "email" && !this.subject) {
        return this.$store.dispatch(
          "createErrors",
          "Please add a subject to this notification template."
        );
      }

      let template = {
        notificationTemplateName: this.name,
        clientID: this.getClientInContext.client_id,
      };

      if (this.notifyType == "email") {
        template.emailSubject = this.subject;
      }

      let result = await this.$store.dispatch(
        "createNotificationTemplate",
        template
      );
      if (result) {
        [this.notifyType, this.name, this.templateMeta] = [null, null, []];
        this.$store.dispatch(
          "createAlerts",
          "Notification template has been created!"
        );
        await this.$store.dispatch("getClientNotificationTemplates", true);
        if (this.getClientNotificationTemplateData) {
          this.notificationTemplates = this.getClientNotificationTemplateData;
        }
      }
    },
    editTemplate(meta) {
      this.$router
        .push({
          name: "ClientNotificationTemplateEdit",
          params: {
            notificationTemplateID: meta.notification_template_id,
          },
        })
        .catch(() => {});
    },
    async setClient() {
      await this.$store.dispatch("getClientInContext", {
        client_id: this.selected.client_id,
      });
      // will fetch anyway
      await this.$store.dispatch("getClientNotificationTemplates", true);
      if (this.getClientNotificationTemplateData) {
        this.notificationTemplates = this.getClientNotificationTemplateData;
      }
    },
    convertDate(date) {
      if (!date) {
        // if no date, do not use.
        return null;
      }
      let momentObj = moment(date);
      if (!momentObj || !momentObj.isValid()) {
        return null;
      }
      return momentObj.format("MMMM DD, YYYY h:mm A");
    },
  },
};
</script>
