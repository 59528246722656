<template>
  <b-modal v-model="showModal" @hidden="onClose" title="Add New Client">
    <div :class="theme || ''">
      <form @submit.prevent="onSubmit">
        <b-form-group class="form-group">
          <label class="float-left ml-2 h6">Client Name: </label>
          <input
            type="text"
            required
            placeholder="Client Name"
            class="form-control form-control-md"
            v-model="form.clientName"
          />
        </b-form-group>

        <b-form-group class="form-group">
          <label class="float-left ml-2 h6">Client Contact (optional): </label>
          <input
            type="text"
            placeholder="Website"
            class="form-control form-control-md mb-2"
            v-model="form.contact.website"
          />
          <input
            type="email"
            placeholder="Support Email"
            class="form-control form-control-md mb-2"
            v-model="form.contact.supportEmail"
          />
          <input
            type="address"
            placeholder="Street Address"
            class="form-control form-control-md mb-2"
            v-model="form.contact.address1"
          />
          <input
            type="address"
            placeholder="Suite, Apt, etc"
            class="form-control form-control-md mb-2"
            v-model="form.contact.address2"
          />
          <input
            type="address"
            placeholder="Address 3"
            class="form-control form-control-md mb-2"
            v-model="form.contact.address3"
          />
          <input
            type="text"
            placeholder="City"
            class="form-control form-control-md mb-2"
            v-model="form.contact.city"
          />
          <input
            type="text"
            placeholder="State"
            class="form-control form-control-md mb-2"
            v-model="form.contact.state"
          />
          <input
            type="text"
            placeholder="Country"
            class="form-control form-control-md mb-2"
            v-model="form.contact.country"
          />
          <input
            type="number"
            placeholder="Zip Code"
            class="form-control form-control-md mb-2"
            v-model="form.contact.postalCode"
          />
          <input
            type="tel"
            placeholder="Phone Number (ex. XXX-XXX-XXXX)"
            class="form-control form-control-md mb-2"
            v-model="form.contact.phoneNumber"
          />
        </b-form-group>

        <!--
				<b-form-group class="form-group">
					<label class="float-left ml-2 h6">Choose Client Theme:</label>
					<b-form-select size="sm" class="form-control form-control-md mb-2" v-model="theme" :options="options" @change="previewTheme()"></b-form-select>
				</b-form-group>-->

        <b-form-group class="form-group">
          <b-form-file
            class="form-file"
            accept=".png"
            id="file-large"
            size="md"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            @change="onFileUpload($event)"
          ></b-form-file>
        </b-form-group>

        <b-form-group class="form-group">
          <label class="float-left ml-2 h6"
            >Primary Client Account Admin:
          </label>
          <input
            type="text"
            required
            placeholder="Name"
            class="form-control form-control-md mb-2"
            v-model="form.ownerName"
          />
          <input
            type="email"
            required
            placeholder="Email"
            class="form-control form-control-md"
            v-model="form.ownerEmail"
          />
        </b-form-group>
      </form>
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <button
          type="button"
          class="btn-outline-blue mr-3"
          @click.stop.prevent="onClose"
        >
          Close
        </button>
        <button
          type="submit"
          :class="
            theme == 'aqua'
              ? 'btn btn-info btn-block'
              : theme == 'dark'
              ? 'btn btn-secondary btn-block'
              : 'btn-blue'
          "
          @click.prevent="onSubmit"
          v-if="!loading"
        >
          Add Client
        </button>
        <button
          :class="
            theme == 'aqua'
              ? 'btn btn-info btn-block'
              : theme == 'dark'
              ? 'btn btn-dark btn-block'
              : 'btn-blue'
          "
          disabled
          v-if="loading"
        >
          <b-spinner type="grow"></b-spinner>
          Loading...
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { Auth, API } from "aws-amplify";
import axios from "axios";

export default {
  data() {
    return {
      form: {
        clientName: "",
        ownerEmail: "",
        ownerName: "",
        file: null,
        contact: {
          website: null,
          supportEmail: null,
          address1: null,
          address2: null,
          address3: null,
          city: null,
          state: null,
          country: null,
          postalCode: null,
          phoneNumber: null,
        },
      },
      theme: null,
      options: [
        { text: "Please select a theme...", value: null },
        { text: "Aqua", value: "aqua" },
        { text: "Dark", value: "dark" },
      ],
      errors: [],
      loading: false,
      showModal: false,
    };
  },
  props: ["show", "onClose"],
  async mounted() {
    // make sure to blow out any clients in context while here
    this.$store.commit("setClientInContext", null);
  },
  computed: {},
  methods: {
    async onSubmit() {
      this.loading = true;

      this.validate();
      if (this.errors.length > 0) {
        this.errors.unshift(
          "Please ensure the following fields have been correctly filled out:"
        );
        await this.$store.dispatch("createErrors", this.errors);
        return ([this.errors, this.loading] = [[], false]);
      }

      const { clientName, ownerEmail, ownerName, contact, file } = this.form;

      const newClient = {
        clientName: clientName,
        ownerEmail: ownerEmail,
        ownerName: ownerName,
        clientTheme: this.theme,
        contact: contact,
      };

      const client = await this.$store.dispatch(
        "createClientInstance",
        newClient
      );

      if (client.error) {
        this.loading = false;
        await this.$store.dispatch(
          "createErrors",
          `There was an issue creating ${clientName}: ${client.error}`
        );
        return;
      }

      if (file) {
        if (
          (await this.$store.dispatch("uploadClientLogo", {
            clientID: client.group.client_id,
            fileData: file,
          })) == false
        ) {
          await this.$store.dispatch(
            "createAlerts",
            `${clientName} has been created, but the logo failed to upload`
          );

          this.loading = false;
          this.$router.push({ name: "Home" }).catch(() => {});
          return;
        }
      }

      await this.$store.dispatch(
        "createAlerts",
        `${clientName} has been created!`
      );

      this.$router.push({ name: "Home" }).catch(() => {});
      this.loading = false;
      window.location.reload();
    },
    validate() {
      this.errors = [];
      const { clientName, ownerEmail, ownerName, contact, file } = this.form;

      if (!clientName || clientName.length <= 0) {
        this.errors.push("Client name");
      }

      if (ownerName.length <= 0 || !ownerName) {
        this.errors.push("Client admin name");
      }

      if (file && file.type != "image/png") {
        this.errors.push("Image of type .png");
      }

      if (!file) {
        this.errors.push("Client logo");
      }

      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (ownerEmail.length <= 0 || !ownerEmail || !re.test(ownerEmail)) {
        this.errors.push("Valid e-mail");
      }

      re = /^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/;
      if (contact.phoneNumber && !re.test(contact.phoneNumber)) {
        this.errors.push("Valid phone number (XXX-XXX-XXXX)");
      }

      re = /[0-9]/;
      if (contact.postalCode && !re.test(contact.postalCode)) {
        this.errors.push("Valid zip code");
      }
    },
    async onFileUpload(e) {
      this.form.file = (await e.target.files[0]) || e.dataTransfer.files[0];
    },
    previewTheme() {
      this.theme = this.theme == null ? "add-client-form" : this.theme;
    },
  },
  watch: {
    show(val) {
      this.showModal = val;
    },
  },
};
</script>
