<template>
	<div class="widget-container">
		<div class="video-widget">
			<input type="url" v-model="video" placeholder="https://video.url/" @change="saved=false"/>
		</div>
		<div v-if="video">
			<vue-plyr>
				<div class="plyr__video-embed">
					<iframe :src="video" allowfullscreen allowtransparency allow="autoplay"></iframe>
				</div>
			</vue-plyr>
		</div>
		<button class="btn-blue w-75 mx-auto mt-4 mb-2" size="sm" @click.prevent="submitVideo" v-if="!saved">Submit</button>
	</div>
</template>

<script>
import Vue from 'vue'
import VuePlayer from '../VuePlayer'
Vue.use(VuePlayer);

export default {
	props: ['index', 'templateMeta'],
	data() {
		return {
			video: '',
			saved: false
		}
	},
	mounted() {
		if (this.templateMeta && this.templateMeta != []) {
			if (this.templateMeta.data && this.templateMeta.data.content) {
				this.video = this.templateMeta.data.content.url || '';
				this.saved = true;
			}
		}
	},
	methods: {
		async submitVideo() {
			if (!this.video) {
				return this.$store.dispatch('createErrors', 'Please add a video url.');
			}
			const data = {
				content: {
					url: this.video,
				},
				index: this.index
			};
			this.$bus.$emit('notifyWidgetSaved', data);
			this.saved = true;
		}
	}
}
</script>
