<template>
	<div class="home">
		<template v-if="getIsAuthorized">
			<Admin v-if="getUserIsAdministrator"/>
			<Manager v-else-if="getUserIsManager || getUserIsClientAdmin"/>
		</template>
	</div>
</template>

<script>
	import Admin from './Admin.vue';
	import Manager from './Manager.vue';
	import { mapGetters } from 'vuex';

	export default {
		name: 'Home',
		components: { Admin, Manager },
		data(){
			return{}
		},
		computed: {
			...mapGetters(['getIsAuthorized', 'getUserIsAdministrator', 'getUserIsManager', 'getUserIsClientAdmin', 'getUser'])
		},
		async beforeCreate() {
			if (!this.getUser) {
				await this.$store.dispatch('fetchUser');
			}
		},
		async mounted() {
			if (!this.getUser) {
				await this.$store.dispatch('fetchUser');
			}
		},
	};
</script>
