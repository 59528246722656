<template>
  <b-container class="content-container">
    <h3 class="m-2">User Management</h3>
    <b-modal
      v-b-modal.deactivateUser
      id="deactivateUser"
      variant="light"
      dismissible
      class="w-100 mx-auto"
      v-if="userToDeactivate"
    >
      <p>
        Are you sure you want to deactivate user "{{ userToDeactivate.name }}"
        <template v-if="userToDeactivate.email"
          >({{ userToDeactivate.email }}) </template
        >for client {{ userToDeactivate.group }}? All data will be lost.
      </p>
      <div slot="modal-footer">
        <button
          class="btn-sm btn-danger mt-3"
          @click="
            () => {
              deactivateUser();
            }
          "
        >
          I'm Sure
        </button>
        <button
          class="btn-sm btn-outline-danger mx-2"
          @click="
            () => {
              userToDeactivate = null;
              $bvModal.hide('deactivateUser');
            }
          "
        >
          Cancel
        </button>
      </div>
    </b-modal>
    <div class="text-center" v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div class="pl-5" v-if="editPersonalAccount">
      <div class="row justify-content-between w-100">
        <h5 class="text-left">Your account info:</h5>
        <div class="row">
          <button class="btn-blue mr-3" @click="updateUser()">
            Save Changes
          </button>
          <button
            v-if="this.getUserIsAdministrator || this.getUserIsClientAdmin"
            class="btn-outline-blue"
            @click="getUsersForClient"
          >
            Manage Client Users
          </button>
        </div>
      </div>
      <hr class="w-100" />
      <div class="col text-left">
        <h6>Your Name:</h6>
        <input
          class="form-control form-control-sm w-50"
          v-model="currentUser.name"
          placeholder="Mr. John Smith Esq."
        />
      </div>
      <div class="col text-left">
        <h6 class="mt-2">Email:</h6>
        <p class="text-muted">{{ currentUser.email }}</p>
      </div>
      <div class="col text-left">
        <h6 class="mt-2">Phone Number:</h6>
        <input
          class="form-control form-control-sm w-50"
          v-model="currentUser.phoneNumber"
          placeholder="(XXX)-XXX-XXXX"
        />
      </div>
      <div class="col text-left">
        <h6 class="mt-2">Password:</h6>
        <button class="btn-outline-blue" v-b-toggle.user-password>Edit</button>
        <b-collapse id="user-password" class="w-50">
          <b-card class="col">
            <a class="float-right back-btn h6" v-b-toggle.user-password
              >&times;</a
            >
            <label class="w-100 text-left">
              Please enter your current password:
            </label>
            <input
              type="password"
              class="form-control w-100 mb-2"
              placeholder="Previous password"
              v-model="passwordUpdate.current"
            />
            <label class="w-100 text-left">
              Please enter your new password:
            </label>
            <input
              type="password"
              autocomplete="new-password"
              data-lpignore="true"
              class="form-control w-100 mb-2"
              placeholder="New password"
              v-model="passwordUpdate.newPass1"
            />
            <label class="w-100 text-left">
              Re-enter your new password:
            </label>
            <input
              class="form-control w-100 mb-2"
              placeholder="New password (again)"
              v-model="passwordUpdate.newPass2"
            />
            <button
              class="btn-outline-blue w-50 mx-auto"
              @click="changePassword"
            >
              Submit
            </button>
          </b-card>
        </b-collapse>
      </div>
    </div>
    <div v-else-if="editAllAccounts">
      <div class="row justify-content-between mx-2">
        <a
          @click.prevent="
            () => {
              editAllAccounts = false;
              editPersonalAccount = true;
            }
          "
          class="float-left m-2 back-btn"
          ><i class="far fa-arrow-alt-circle-left"></i> Back to account
          overview</a
        >
      </div>

      <b-container class="display-table-header">
        <div class="d-flex">
          <h5 class="my-auto mr-2">
            {{ clientNames[clientUsersInViewID] }} Users
          </h5>

          <router-link :to="{ name: 'AddUser' }" class="btn-outline-blue"
            >Add New User</router-link
          >
        </div>

        <div
          class="mt-3"
          v-if="
            getUserIsAdministrator &&
              Array.isArray(cognitoUserGroupOptions) &&
              cognitoUserGroupOptions.length > 0
          "
        >
          <b-form-select
            v-model="clientUsersInViewID"
            @change="getUsersForClient()"
            :options="cognitoUserGroupOptions"
          ></b-form-select>
        </div>
      </b-container>
      <div v-if="clientUsersInViewID" class="mt-3">
        <div
          v-if="
            Array.isArray(clientUsersInView) && clientUsersInView.length > 0
          "
        >
          <b-container class="display-table">
            <b-card v-for="(user, i) in clientUsersInView" :key="i">
              <b-row class="display-table-row">
                <b-col>{{ user.name }}</b-col>
                <b-col class="assigned-to">{{ user.email }}</b-col>
                <b-col>
                  <span class="text-success" v-if="user.clientAdmin"
                    >Admin</span
                  >
                </b-col>
                <b-col>
                  <!-- assume group means client is or is not admin -->
                  <button
                    v-if="
                      user.group &&
                        (getUserIsAdministrator ||
                          (getUserIsClientAdmin && !user.clientAdmin)) &&
                        user.username != getUser.userName
                    "
                    class="btn-blue w-100"
                    v-b-modal.deactivateUser
                    @click="userToDeactivate = user"
                  >
                    Deactivate User
                  </button>
                </b-col>
              </b-row>
            </b-card>
          </b-container>
        </div>
        <div class="mt-3" v-else-if="!loading">
          <h5>No users are part of this client</h5>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      editAllAccounts: false,
      editPersonalAccount: true,
      isSubmitting: false,
      currentUser: {
        name: "",
        email: "",
        phoneNumber: "",
      },
      originalUser: null,
      allUsers: null,
      userToDeactivate: null,
      clientUsersInViewID: null,
      cognitoUserGroupOptions: [],
      clientNames: {},
      clientUsersInView: [],
      passwordUpdate: {
        current: null,
        newPass1: null,
        newPass2: null,
      },
    };
  },
  async mounted() {
    if (this.getUserIsAdministrator) {
      // obliterate client context NOW
      try {
        await this.$store.commit("setResetClientInContext");
      } catch {
        this.$router.replace({ name: "Home" }).catch(() => {});
      }
    }
    await this.fetchUser;
    if (this.getUser) {
      this.currentUser = {
        name: this.getUser.attributes.name,
        email: this.getUser.attributes.email,
        phoneNumber: this.getUser.attributes.phone_number || "",
      };
      this.originalUser = {
        name: this.getUser.attributes.name,
        email: this.getUser.attributes.email,
        phoneNumber: this.getUser.attributes.phone_number || "",
      };
    } else {
      this.currentUser = {
        name: "",
        email: "",
        phoneNumber: "",
      };
      this.originalUser = null;
    }

    if (this.getUserIsAdministrator) {
      await this.$store.dispatch("getClientInstances");
      if (this.getClients.length > 0) {
        let clients = this.getClients;
        this.cognitoUserGroupOptions = [];
        if (clients.length > 0) {
          this.cognitoUserGroupOptions.push({
            value: null,
            text: "Select a Client to manage",
          });
        }
        for (let i in clients) {
          this.cognitoUserGroupOptions.push({
            value: clients[i].client_id,
            text: clients[i].client_name,
          });
          this.clientNames[clients[i].client_id] = clients[i].client_name;
        }
      }
    } else if (this.getUserIsClientAdmin) {
      let client = this.getClientInContext;
      this.clientNames[client.client_id] = client.client_name;
      this.clientUsersInViewID = client.client_id;
    }

    this.$bus.$emit("breadcrumbData", [
      {
        text: "Account Settings",
        to: { name: "UserManagement" },
      },
    ]);
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getUserIsAdministrator",
      "getUserIsClientAdmin",
      "getClients",
      "getClientInContext",
    ]),
  },
  methods: {
    ...mapActions(["fetchUser"]),
    async updateUser() {
      if (this.isSubmitting == (this.isSubmitting = true)) {
        return false;
      }
      try {
        if (!this.currentUser.name) {
          throw new Error("You are required to have a name");
        }
        if (!this.currentUser.phoneNumber && this.originalUser.phoneNumber) {
          throw new Error(
            "After adding a phone number to your account, it can not be removed"
          );
        }
        if (
          this.originalUser &&
          this.currentUser.name == this.originalUser.name &&
          this.currentUser.phoneNumber == this.originalUser.phoneNumber
        ) {
          // do nothing.
          return false;
        }
        let payload = {};
        if (this.currentUser.name != this.originalUser.name) {
          payload.name = this.currentUser.name;
        }
        if (this.currentUser.phoneNumber != this.originalUser.phoneNumber) {
          if (this.currentUser.phoneNumber) {
            let trimmedNumber = this.currentUser.phoneNumber
              .toString()
              .trim()
              .toLowerCase();
            let addPlus = trimmedNumber[0] == "+";
            trimmedNumber = trimmedNumber.replace(/[^\dx]/g, "");
            //split on extension
            let checkParts = trimmedNumber.split("x");
            if (checkParts.length > 2) {
              throw new Error(
                "Invalid phone number provided, more than one extension field detected"
              );
            }
            // very least validate the start portion
            if (
              checkParts[0].length < 10 + (addPlus ? -3 : 0) ||
              checkParts[0].length > 20
            ) {
              // minimum 7 for noted extension ( smallest is 3 digit country + 4 digit number), 10 without. cannot detect max if country is there or not, so assume 20 max
              throw new Error(
                "Invalid phone number provided: " + checkParts[0]
              );
            }
            if (checkParts.length == 2) {
              if (checkParts[1].length == 0 || checkParts[1].length > 11) {
                throw new Error(
                  "Invalid phone number provided, extension code sent but invalid extension passed (between 1-11 digits)"
                );
              }
            }
            // force add USA country code if length == 10.
            payload.phone_number =
              "+" + (trimmedNumber.length == 10 ? "1" : "") + trimmedNumber;
          } else {
            payload.phone_number = "";
          }
        }
        if (
          await Auth.currentAuthenticatedUser()
            .then(async (user) => {
              return await Auth.updateUserAttributes(user, payload)
                .then(() => {
                  this.$store.dispatch(
                    "createAlerts",
                    "User was successfully updated"
                  );
                  return true;
                })
                .catch((err) => {
                  this.$store.dispatch(
                    "createErrors",
                    err?.message || "Update failed, please try again."
                  );
                  return false;
                });
            })
            .catch((err) => {
              this.$store.dispatch(
                "createErrors",
                err?.message || "Update failed, please try again."
              );
              return false;
            })
        ) {
          await this.fetchUser;
          if (payload.phone_number) {
            this.currentUser.phoneNumber = payload.phone_number;
          }
          this.originalUser = {
            name: this.currentUser.name,
            phoneNumber: this.currentUser.phoneNumber,
            email: this.currentUser.email,
          };
        }
      } catch (err) {
        await this.$store.dispatch(
          "createErrors",
          err?.message || "Update failed, please try again."
        );
      } finally {
        this.isSubmitting = false;
      }
    },
    async changePassword() {
      if (this.isSubmitting == (this.isSubmitting = true)) {
        return false;
      }
      try {
        if (!this.passwordUpdate.current) {
          throw new Error("Please enter your current password");
        } else if (
          this.passwordUpdate.newPass1 != this.passwordUpdate.newPass2
        ) {
          throw new Error("New Passwords do not match");
        } else if (!this.passwordUpdate.newPass1) {
          throw new Error(
            "You must enter a new password if you want to change passwords"
          );
        } else if (
          this.passwordUpdate.newPass1 == this.passwordUpdate.current
        ) {
          // not a real error, do not throw
          this.$store.dispatch(
            "createAlerts",
            "Password should be different from old password to be updated"
          );
          return false;
        }
        if (
          await Auth.currentAuthenticatedUser()
            .then(async (user) => {
              return await Auth.changePassword(
                user,
                this.passwordUpdate.current,
                this.passwordUpdate.newPass1
              )
                .then(() => {
                  this.$store.dispatch(
                    "createAlerts",
                    "Your password was successfully updated"
                  );
                  return true;
                })
                .catch((err) => {
                  this.$store.dispatch(
                    "createErrors",
                    err?.message || "Update failed, please try again."
                  );
                  return false;
                });
            })
            .catch((err) => {
              this.$store.dispatch(
                "createErrors",
                err?.message || "Update failed, please try again."
              );
              return false;
            })
        ) {
          await this.$root.$emit("bv::toggle::collapse", "user-password");
          this.passwordUpdate = {
            current: null,
            newPass1: null,
            newPass2: null,
          };
        }
      } catch (err) {
        await this.$store.dispatch(
          "createErrors",
          err?.message || "Update failed, please try again."
        );
      } finally {
        this.isSubmitting = false;
      }
    },
    async getUsersForClient() {
      if (!this.getUserIsAdministrator && !this.getUserIsClientAdmin) {
        return false;
      }
      this.loading = true;
      try {
        this.clientUsersInView = !this.clientUsersInViewID
          ? []
          : await this.$store.dispatch(
              "getUsersForClient",
              this.clientUsersInViewID
            );
        this.editPersonalAccount = false;
        this.editAllAccounts = true;
      } catch (e) {
        this.$store.dispatch("createErrors", e.message);
      } finally {
        this.loading = false;
      }
    },
    async deactivateUser() {
      if (
        (!this.getUserIsAdministrator && !this.getUserIsClientAdmin) ||
        !this.userToDeactivate
      ) {
        return false;
      } else if (this.isSubmitting == (this.isSubmitting = true)) {
        return false;
      }
      this.loading = true;
      try {
        if (
          await this.$store.dispatch(
            "disableUser",
            this.userToDeactivate.username
          )
        ) {
          this.clientUsersInView = await this.$store.dispatch(
            "getUsersForClient",
            this.clientUsersInViewID
          );
          this.userToDeactivate = false;
          this.$bvModal.hide("deactivateUser");
        }
      } catch (e) {
        this.$store.dispatch("createErrors", e.message);
      } finally {
        this.loading = false;
        this.isSubmitting = false;
      }
    },
  },
};
</script>
