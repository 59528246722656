<template>
	<div>
		<div class=" mb-5">
			<component :is="currentComponent" class="content-container" />
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex';
	import ManagerDashboard from '@/components/manager/ManagerDashboard';

	export default {
		components: { ManagerDashboard },
		data() {
			return {
				currentComponent: 'ManagerDashboard',
				showSidebar: false,
			};
		},
		mounted() {
			this.$bus.$emit('breadcrumbData', []);
			this.fetchUser;
		},
		computed: {
			...mapGetters(['getUser'])
		},
		methods: {
			...mapActions(['fetchUser']),
		}
	};
</script>
<style>
@import '../assets/css/main.css';
</style>
