<template>
  <ContentContainer :hideBack="true">
    <template #title>
      <h4>Client Instances</h4>
    </template>
    <template #header>
      <button
        class="btn-blue float-left"
        @click="showModal = true"
        v-if="!loading"
      >
        Add Client
      </button>
    </template>
    <div class="text-center mx-auto" v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <template v-else>
      <div v-if="clients != null">
        <AddClientModal
          :show="showModal"
          :onClose="() => (showModal = false)"
        ></AddClientModal>
        <div class="mb-3">
          <b-form-input
            id="client-search"
            placeholder="Search by client name"
            @input="searchClient"
          ></b-form-input>
        </div>
        <div class="display-table clickable">
          <b-card
            v-for="client in filteredClients"
            :key="client.client_id"
            @click.prevent="viewClient(client)"
          >
            <b-row class="display-table-row">
              <b-col cols="3">
                <img
                  v-if="client.logo"
                  :src="client.logo"
                  class="w-auto h-8"
                  :alt="client.client_name"
                />
                <span v-else>
                  {{ client.client_name }}
                </span>
              </b-col>
              <b-col>
                {{ client.client_name }}
              </b-col>
              <b-col
                >{{ client.active_workflow_count }} active workflow{{
                  client.active_workflow_count === 1 ? "" : "s"
                }}</b-col
              >
              <b-col
                >{{ client.active_timeline_count }} active timeline{{
                  client.active_timeline_count === 1 ? "" : "s"
                }}</b-col
              >
            </b-row>
          </b-card>
        </div>
      </div>
    </template>
  </ContentContainer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ContentContainer from "@/components/templates/ContentContainer";
import AddClientModal from "./actions/AddClient";

export default {
  data() {
    return {
      clients: null,
      options: [{ value: null, text: "Please select a client" }],
      selected: null,
      loading: false,
      filter: "",
      showModal: false,
    };
  },
  async mounted() {
    this.loading = true;
    try {
      if (this.getClients && this.getClients.length === 0) {
        await this.$store.dispatch("getClientInstances");
      }
      this.$bus.$emit("breadcrumbData", []);

      this.clients = await Promise.all(
        this.getClients.map(async (client) => ({
          ...client,
          logo: await this.getClientLogo(client.client_id),
        }))
      );

      if (this.clients.length > 0) {
        for (let i in this.clients) {
          this.options.push({
            value: this.clients[i],
            text: this.clients[i].client_name,
          });
        }
      }
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters(["getClients", "getClientInContext"]),
    filteredClients() {
      if (this.filter === "") {
        return this.clients;
      }
      return this.clients.filter((client) => {
        return (
          client.client_name.toLowerCase().indexOf(this.filter.toLowerCase()) >
          -1
        );
      });
    },
  },
  components: {
    ContentContainer,
    AddClientModal,
  },
  methods: {
    ...mapActions(["getClientLogo"]),
    async viewClient(client) {
      try {
        this.loading = true;
        if (client !== null) {
          await this.$store.dispatch("getClientInContext", client);
          this.$router
            .push({
              name: "ClientInfo",
              params: { clientID: client.client_id },
            })
            .catch(() => {});
        }
      } finally {
        this.loading = false;
      }
    },
    searchClient(value) {
      this.filter = value;
    },
  },
};
</script>
