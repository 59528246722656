<template>
	<div v-if="client">
		<b-modal
			v-if="getUserIsAdministrator && client"
			id="regenerateApiKeyModal"
			variant="light dismissible"
		>
			<div v-if="client.aws_external_api_key_value">
				Are you sure you want to regenerate the clients api key? The client will need to update all usage correctly.
				<strong>This cannot be undone!</strong>
			</div>
			<div v-else>
				This will enable the external apis for this client, and generate them a key. Are you sure you want to enable?
			</div>
			<div slot="modal-footer">
				<button
					class="btn btn-blue mr-3 d-inline-block"
					@click="
						() => {
							$bvModal.hide('regenerateApiKeyModal');
							regenerateApiKey();
						}
					"
				>
					<template v-if="!client.aws_external_api_key_value"> Generate Key </template>
					<template v-else> Regenerate Key </template>
				</button>
				<button
					class="btn btn-secondary d-inline-block"
					@click="$bvModal.hide('regenerateApiKeyModal')"
				>
					Cancel
				</button>
			</div>
		</b-modal>
		<ContentContainer>
			<template #title
				><strong>{{ client.client_name }}</strong> at a glance...</template
			>
			<template #header>
				<div>
					<button
						class="btn-blue"
						v-b-modal.edit-client
					>
						<i class="far fa-edit"></i> Edit Client
					</button>
				</div>
			</template>
			<div
				class="d-flex w-100 mb-3"
				v-if="client != null"
			>
				<div class="w-100">
					<b-card class="w-100 h-100">
						<b-container class="w-100 text-center d-flex">
							<b-row class="mx-auto">
								<b-col class="stats-container">
									<strong>Active Workflows</strong>
									<div>
										<b>
											{{ client.stats && client.stats.active_workflows ? client.stats.active_workflows : 0 }}
										</b>
										<i class="fas fa-stream"></i>
									</div>
								</b-col>
								<b-col class="stats-container">
									<strong>Finalized Wokflows:</strong>
									<div>
										{{ client.stats && client.stats.finalized_workflows ? client.stats.finalized_workflows : 0 }}
										<i class="fas fa-stream"></i>
									</div>
								</b-col>
								<b-col class="stats-container">
									<strong>Active Timelines:</strong>
									<div>
										{{ client.stats && client.stats.active_timelines ? client.stats.active_timelines : 0 }}
										<i class="fas fa-history"></i>
									</div>
								</b-col>
								<b-col class="stats-container">
									<strong>Completed Timelines:</strong>
									<div>
										{{ client.stats && client.stats.completed_timelines ? client.stats.completed_timelines : 0 }}
										<i class="fas fa-history"></i>
									</div>
								</b-col>
							</b-row>
						</b-container>
						<b-container class="d-flex w-100">
							<div class="text-center d-flex justify-content-between mx-auto w-75 flex-wrap">
								<div>
									<strong>Client Admin:</strong>
									<p class="mr-1">
										{{ client.client_contact.client_owner_name }}
									</p>
								</div>
								<div>
									<strong>Client Admin Email:</strong>
									<p style="word-break: break-word">
										{{ client.client_contact.client_owner_email }}
									</p>
								</div>
								<div>
									<strong>Client ID:</strong>
									<p>{{ client.client_id }}</p>
								</div>
							</div>
						</b-container>
						<b-container>
							<div
								class="row"
								v-if="!showApiKey"
							>
								<button
									class="btn-blue mb-2 w-100"
									@click="showApiKey = true"
								>
									Show API Key
								</button>
							</div>
							<div
								class="row d-flex flex-column"
								v-else
							>
								<div>
									<input
										type="disabled"
										:disabled="true"
										:readonly="true"
										class="form-control"
										:value="
											client.aws_external_api_key_value ||
											(getUserIsAdministrator ? 'GENERATE TO ENABLE' : 'None - please contact administrator')
										"
									/>
								</div>
								<div v-if="getUserIsAdministrator && client">
									<button
										class="btn-blue mt-2 mb-2 w-100"
										:disabled="regeneratingApiKey"
										@click="$bvModal.show('regenerateApiKeyModal')"
									>
										<template v-if="regeneratingApiKey">
											<b-spinner
												small
												type="grow"
												class="spinner"
											></b-spinner>
											Loading...
										</template>
										<template v-else>
											<template v-if="client.aws_external_api_key_value"> Regenerate Key </template>
											<template v-else> Generate Key </template>
										</template>
									</button>
								</div>
							</div>
						</b-container>
					</b-card>
				</div>
			</div>
			<b-modal
				title="Edit Client"
				class="w-75 mx-auto"
				id="edit-client"
			>
				<form class="edit-client-form">
					<b-form-group class="form-group">
						<label class="float-left ml-2 h6">Client Name: </label>
						<input
							type="text"
							required
							placeholder="Client Name"
							class="form-control"
							v-model="form.clientName"
						/>
					</b-form-group>

					<b-form-group class="form-group">
						<label class="float-left ml-2 h6">Client Contact (optional): </label>
						<input
							type="text"
							placeholder="Website"
							class="form-control mb-2"
							v-model="form.contact.website"
						/>
						<input
							type="email"
							placeholder="Support Email"
							class="form-control mb-2"
							v-model="form.contact.supportEmail"
						/>
						<input
							type="address"
							placeholder="Street Address"
							class="form-control mb-2"
							v-model="form.contact.address1"
						/>
						<input
							type="address"
							placeholder="Suite, Apt, etc"
							class="form-control mb-2"
							v-model="form.contact.address2"
						/>
						<input
							type="address"
							placeholder="Address 3"
							class="form-control mb-2"
							v-model="form.contact.address3"
						/>
						<input
							type="text"
							placeholder="City"
							class="form-control mb-2"
							v-model="form.contact.city"
						/>
						<input
							type="text"
							placeholder="State"
							class="form-control mb-2"
							v-model="form.contact.state"
						/>
						<input
							type="text"
							placeholder="Country"
							class="form-control mb-2"
							v-model="form.contact.country"
						/>
						<input
							type="number"
							placeholder="Postal Code"
							class="form-control mb-2"
							v-model="form.contact.postalCode"
						/>
						<input
							type="text"
							placeholder="Phone Number"
							class="form-control mb-2"
							v-model="form.contact.phoneNumber"
						/>
					</b-form-group>
					<b-form-group class="form-group">
						<label class="float-left ml-2 h6">Client Convertbox Json (optional):</label>
						<textarea
							class="form-control mb-2"
							v-model="form.convertboxmeta"
						/>
					</b-form-group>
					<b-form-group class="form-group">
						<label class="float-left ml-2 h6">Client ActiveCampaign Settings (optional): </label>
						<input
							type="text"
							placeholder="API Key"
							class="form-control mb-2"
							v-model="form.activecampaign.apiKey"
						/>
						<input
							type="text"
							placeholder="API Url"
							class="form-control mb-2"
							v-model="form.activecampaign.apiUrl"
						/>
					</b-form-group>

					<b-form-group class="form-group">
						<label class="float-left ml-2 h6">Logo (optional): </label>
						<b-form-file
							class="form-file"
							accept=".png"
							id="file-large"
							size="md"
							placeholder="Choose a file or drop it here..."
							drop-placeholder="Drop file here..."
							@change="onFileUpload($event)"
						></b-form-file>
						<div class="client-logo mb-3">
							<div>
								<template v-if="client.logo">
									Current Logo:
									<img
										class="client-logo"
										:src="client.logo"
									/>
								</template>
								<template v-else> No logo currently for this client. </template>
							</div>
						</div>
					</b-form-group>
				</form>
				<template #modal-footer>
					<div class="w-100 d-flex justify-content-between">
						<button
							type="button"
							class="btn-outline-blue mr-3 w-50"
							@click.stop.prevent="
								(e) => {
									$bvModal.hide('edit-client');
								}
							"
						>
							Close
						</button>
						<button
							type="submit"
							class="btn-blue w-50"
							@click.stop.prevent="
								(e) => {
									onSubmit(e);
								}
							"
						>
							Submit
						</button>
					</div>
				</template>
			</b-modal>
			<div
				class="text-center m-4"
				v-if="!client"
			>
				<b-spinner
					variant="primary"
					label="Spinning"
				></b-spinner>
			</div>
		</ContentContainer>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentContainer from '@/components/templates/ContentContainer';

export default {
	data() {
		return {
			client: null,
			showApiKey: false,
			regeneratingApiKey: false,
			form: {
				clientName: '',
				contact: {
					website: null,
					supportEmail: null,
					address1: null,
					address2: null,
					address3: null,
					city: null,
					state: null,
					country: null,
					postalCode: null,
					phoneNumber: null,
				},
				convertboxmeta: null,
				activecampaign: {
					apiKey: null,
					apiUrl: null,
				},
				metaForm: [],
				file: '',
			},
			baseconvertboxmeta: null,
			loading: false,
		};
	},
	async mounted() {
		this.loading = true;

		this.client = this.getClientInContext;

		if (!this.getClientInContext) {
			const clientID = this.$route.params.clientID;
			this.client = await this.$store.dispatch('getClientByID', clientID);

			if (!this.client) {
				return this.$router.replace({ name: 'Home' }).catch(() => {});
			}
		}

		this.form = {
			clientName: this.client.client_name || '',
			contact: {
				website: this.client.client_contact?.website || null,
				supportEmail: this.client.client_contact?.support_email || null,
				address1: this.client.client_contact?.address1 || null,
				address2: this.client.client_contact?.address2 || null,
				address3: this.client.client_contact?.address3 || null,
				city: this.client.client_contact?.city || null,
				state: this.client.client_contact?.state || null,
				country: this.client.client_contact?.country || null,
				postalCode: this.client.client_contact?.postal_code || null,
				phoneNumber: this.client.client_contact?.phone_number || null,
			},
			activecampaign: {
				apiKey: this.client.client_activecampaign?.api_key || null,
				apiUrl: this.client.client_activecampaign?.api_url || null,
			},
		};

		// TODO: delete once convertbox is updated to accept api headers OR dropped
		// NOTE: sequelize converts meta to metum because of the word "data" an how it de-pluralizes it. This is awful.
		this.form.convertboxmeta = this.client.client_convertbox_metum?.meta
			? JSON.stringify(this.client.client_convertbox_metum.meta, undefined, 2)
			: null;
		this.baseconvertboxmeta = JSON.stringify(this.client.client_convertbox_metum?.meta) || null;

		this.$bus.$emit('breadcrumbData', []);

		this.loading = false;
	},
	components: {
		ContentContainer,
	},
	computed: {
		...mapGetters(['getClientInContext', 'getUserIsAdministrator', 'getClients']),
	},
	methods: {
		async onSubmit() {
			const { clientName, contact, convertboxmeta, activecampaign, file } = this.form;

			if (file) {
				let url = await this.$store.dispatch('uploadClientLogo', {
					clientID: this.client.client_id,
					fileData: file,
				});
				if (url === false) {
					return false;
				}
				// url returned, file not broken. add cachebreaker to URL
				url += '?' + new Date().getTime();
				this.client.logo = url;
			}

			// !== null because empty string is a valid way of purging the box.
			if (convertboxmeta !== null) {
				let updateMeta = undefined;
				if (convertboxmeta) {
					// validate it as an object
					try {
						let parsedMeta = JSON.parse(convertboxmeta);
						// validate the json
						let metaKeys = Object.keys(parsedMeta);
						if (metaKeys.length > 0) {
							for (let i in metaKeys) {
								if (
									!parsedMeta[metaKeys[i]] ||
									typeof parsedMeta[metaKeys[i]] != 'object' ||
									!parsedMeta[metaKeys[i]].referers ||
									(typeof parsedMeta[metaKeys[i]].referers != 'string' && !Array.isArray(parsedMeta[metaKeys[i]].referers)) ||
									!parsedMeta[metaKeys[i]].workflow_uuid ||
									typeof parsedMeta[metaKeys[i]].workflow_uuid != 'string' ||
									(parsedMeta[metaKeys[i]].workflow_version &&
										(isNaN(parsedMeta[metaKeys[i]].workflow_version) || parsedMeta[metaKeys[i]].workflow_version <= 0))
								) {
									// required field validations
									throw new Error();
								}

								if (Array.isArray(parsedMeta[metaKeys[i]].referers)) {
									if (parsedMeta[metaKeys[i]].referers.length == 0) {
										throw new Error();
									}
									for (let j in parsedMeta[metaKeys[i]].referers) {
										if (typeof j != 'string') {
											throw new Error();
										}
									}
								}
							}
						}
						if (JSON.stringify(parsedMeta) != this.baseconvertboxmeta) {
							updateMeta = parsedMeta;
						}
					} catch (e) {
						this.$store.dispatch('createErrors', [
							'Provided convertbox meta is invalid json, must be object of: { <convertboxid>: { referers: <refurl|[refurls]>, workflow_uuid: <workflow_uuid>, workflow_version: <optional_workflow_version>}, ...}.',
						]);
						return false;
					}
				} else {
					updateMeta = {};
				}
				if (updateMeta !== undefined) {
					// update the meta
					if (await this.$store.dispatch('clientUpdateConvertboxMeta', updateMeta)) {
						// this has been updated!
						// regenerate
						this.client = await this.$store.dispatch('getClientByID', this.client.client_id);
						this.baseconvertboxmeta = JSON.stringify(this.client.client_convertbox_metum.meta);
					}
				}
			}

			// attempt to update the rest of it.
			// ONLY SEND THE PROPERTIES YOU WISH TO UPDATE (also the client ID)
			let clientUpdate = {
				clientID: await this.client.client_id,
			};

			let clientUpdateCount = 0;
			if (clientName) {
				clientUpdate['clientName'] = clientName;
				clientUpdateCount++;
			}
			if (contact) {
				clientUpdate['contact'] = {};
				let clientContact = await this.client.client_contact;
				let clientContactKeys = Object.keys(this.form.contact);
				for (let i in clientContactKeys) {
					if (contact[clientContactKeys[i]] !== null) {
						// skip non-supplied keys
						clientUpdate['contact'][clientContactKeys[i]] = contact[clientContactKeys[i]];
						// figure out whether or not these are changes.
						// convert the key to snake case
						if (
							clientContact[clientContactKeys[i].replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)] !=
							clientUpdate['contact'][clientContactKeys[i]]
						) {
							clientUpdateCount++;
						}
					}
				}
			}
			if (activecampaign) {
				clientUpdate['activecampaign'] = {};
				let clientActivecampaign = await this.client.client_activecampaign || {};
				let clientActivecampaignKeys = Object.keys(this.form.activecampaign);
				for (let i in clientActivecampaignKeys) {
					if (activecampaign[clientActivecampaignKeys[i]] !== null) {
						// skip non-supplied keys
						clientUpdate['activecampaign'][clientActivecampaignKeys[i]] = activecampaign[clientActivecampaignKeys[i]];
						// figure out whether or not these are changes.
						// convert the key to snake case
						if (
							clientActivecampaign[clientActivecampaignKeys[i].replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)] !=
							clientUpdate['activecampaign'][clientActivecampaignKeys[i]]
						) {
							clientUpdateCount++;
						}
					}
				}
			}
			// assume store handled all errors.
			if (clientUpdateCount > 0) {
				let updatedClient = await this.$store.dispatch('updateClientInstance', clientUpdate);
				if (updatedClient) {
					let tempurl = this.client.logo;
					const clientID = this.$route.params.clientID;
					this.client = await this.$store.dispatch('getClientByID', clientID);
					// this is calculated via a different flow -- don't want to lose it.
					this.client.logo = tempurl;
					this.$store.dispatch('createAlerts', 'Client Successfully Updated');
					this.$bvModal.hide('edit-client');
				}
			}
		},
		returnToClients() {
			this.$router.push({ name: 'Home' }).catch(() => {});
			this.$store.commit('setClientInContext', null);
		},
		async onFileUpload(e) {
			this.form.file = (await e.target.files[0]) || e.dataTransfer.files[0];
			if (!this.form.file || this.form.file.type != 'image/png') {
				this.$store.dispatch('createErrors', ['Client logo must be file of type .Png']);
				this.form.file = null;
				return false;
			}
		},
		async regenerateApiKey() {
			this.regeneratingApiKey = true;
			try {
				await this.$store.dispatch('regenerateClientApiKey');
				// regenerate
				this.client = await this.$store.dispatch('getClientByID', this.client.client_id);
			} finally {
				this.regeneratingApiKey = false;
			}
		},
	},
};
</script>
