<template>
  <div>
    <div class="d-flex w-75 mb-3">
      <b-avatar variant="primary" class="mr-2">M</b-avatar>
      <div class="mr-1">
        <strong>MDME</strong>
        <div class="assigned-to">to me <i class="fas fa-chevron-down"></i></div>
      </div>
      <span class="assigned-to mt-1">{{ exampleEmail }}</span>
    </div>
    <div class="w-75 mx-auto">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exampleEmail: "<example@mdmediaexperience.com>",
    };
  },
};
</script>

<style scoped></style>
