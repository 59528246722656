<template>
  <div class="notifications">
    <div class="dropdown dropleft">
      <button
        class="btn btn-secondary"
        type="button"
        id="notificationDropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div class="notification-bell">
          <i
            class="fas fa-bell green"
            v-if="notifications && notifications.length > 0"
          ></i>
          <i class="far fa-bell" v-else></i>
          <div
            class="notification-exists"
            v-if="notifications && notifications.length > 0"
          ></div>
        </div>
      </button>
      <ul class="dropdown-menu" aria-labelledby="notificationDropdown">
        <li class="notification-header">
          <h5 class="">Updates</h5>
          <a class="font-italic" @click="notifications = null"
            >Clear All <i class="fas fa-trash"></i
          ></a>
        </li>
        <hr class="underline" />
        <p
          v-if="notifications === null || notifications.length === 0"
          class="mt-2"
        >
          You do not have any notifications
        </p>
        <div v-if="notifications != null">
          <li
            v-for="(notification, i) in notifications"
            :key="i"
            :class="
              i % 2
                ? 'notification-item my-1'
                : 'notification-item bg-light my-1'
            "
          >
            <p class="p-0 m-0">
              <strong>New Task:</strong> {{ notification.task_name }}!
            </p>
            <div class="notification-footer font-italic">
              <p v-if="notification.expiration_datetime">
                Expires:
                {{ convertDate(notification.expiration_datetime) || null }}
              </p>
              <p v-else>
                Updated:
                {{ convertDate(notification.last_update_datetime) || null }}
              </p>
              <a class="ml-1" @click="startTask(notification)"
                >Start Task <i class="fas fa-play"></i
              ></a>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      notifications: null,
      client: null,
    };
  },
  async mounted() {
    this.getNotifications();
  },
  watch: {
    getClientInContext: function() {
      return this.getNotifications();
    },
  },
  computed: { ...mapGetters(["getClientInContext", "getUser"]) },
  methods: {
    async getNotifications() {
      if (this.getClientInContext && this.getUser) {
        let timelines = await this.$store.dispatch("getUserTaskNotifications", {
          clientID: this.getClientInContext.client_id,
          managerID: this.getUser.attributes.sub,
        });
        if (timelines) {
          this.notifications = timelines;
        }
      }
    },
    convertDate(date) {
      const strDate = moment(date).format("MM/DD/YYYY h:mm a");
      return strDate;
    },
    async startTask(task) {
      let timeline = await this.$store.dispatch("getTimelineTask", {
        timelineID: task.timeline_id,
        consumerID: task.consumer_id,
        taskGroupTaskID: task.timeline_task_group_task_uuid,
      });

      if (timeline) {
        this.$router
          .push({
            name: "ClientManagerTimelineTask",
            params: {
              timelineID: task.timeline_id,
              taskUUID: task.timeline_task_group_task_uuid,
              clientID: this.getClientInContext.client_id,
            },
          })
          .catch(() => {});
      }
    },
  },
};
</script>
