<template>
  <b-container class="content-container relative">
    <BackButton v-if="!hideBack" />
    <b-container>
      <div class="d-flex justify-content-between border-bottom pb-2 mb-2">
        <h4 class="text-left my-auto">
          <slot name="title"></slot>
        </h4>
        <slot name="header"></slot>
      </div>
      <slot></slot>
    </b-container>
  </b-container>
</template>

<script>
import BackButton from "@/components/nav/BackButton.vue";

export default {
  components: { BackButton },
  props: ["hideBack"],
};
</script>
