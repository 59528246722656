
/* eslint-disable */

const awsmobile = {
	aws_project_region: 'us-east-1',
	aws_cognito_identity_pool_id: 'us-east-1:374635f2-ce71-4cec-aca3-82077789cc45',
	aws_cognito_region: 'us-east-1',
	aws_user_pools_id: 'us-east-1_1DJhBAG5R',
	aws_user_pools_web_client_id: 'fjdtr56ls41in1oa21j7bm7dr',
	oauth: {},
	debug: '0',
	aws_cloud_logic_custom: [
		{
			name: 'management',
			endpoint: 'https://sandbox.mdmesolutions.com/api',
			region: 'us-east-1'
		},
		{
			name: 'admin',
			endpoint: 'https://sandbox.mdmesolutions.com/api',
			region: 'us-east-1'
		},
		{
			name: 'external',
			endpoint: 'https://sandbox.mdmesolutions.com/api',
			region: 'us-east-1'
		},
		{
			name: 'files',
			endpoint: 'https://sandbox.mdmesolutions.com/api',
			region: 'us-east-1'
		},
		{
			name: 'consumers',
			endpoint: 'https://sandbox.mdmesolutions.com/api',
			region: 'us-east-1'
		}
	],
	aws_user_files_s3_bucket: 'mdme-public72624-sandbox',
	aws_user_files_s3_bucket_region: 'us-east-1'
};

export default awsmobile;
