import Vue from 'vue';
import Vuex from 'vuex';
import { Auth, API } from 'aws-amplify';
import axios from 'axios';
import router from '../router';

Vue.use(Vuex);

const apiName = 'management';

const widgetIcons = {
	TextEdit: 'fas fa-font',
	Photo: 'far fa-image',
	Video: 'fas fa-video',
	Calendar: 'far fa-calendar-plus',
	Download: 'fas fa-download',
	Questionnaire: 'fas fa-clipboard-list',
	Upload: 'fas fa-file-upload'
};

// helper functions to just reduce code replication

export default new Vuex.Store({
	state: {
		authorized: false,
		user: null,
		userInfo: null,
		userIsManager: false,
		userIsClientAdmin: false,
		userIsAdministrator: false,
		resetUser: null,
		userData: null,
		groups: null,
		clients: [],
		clientInContext: null,
		clientUsers: null,
		clientConsumers: null,
		clientNotificationTemplates: null,
		workflows: [],
		workflowInContext: null,
		workflowMeta: null,
		taskInContext: null,
		timelines: [],
		timelineInContext: null,
		timelineTaskWidgets: null,
		notificationTemplateInContext: null,
		errors: [],
		alerts: [],
		currentComponent: '',
		s3_bucket: null,
		s3_region: null
	},
	getters: {
		getIsAuthorized: (state) => state.authorized,
		getUser: (state) => state.user,
		getUserInfo: (state) => state.userInfo,
		getUserMustVerifyEmail: (state) => {
			if (!state.authorized || !state.user || !state.userInfo) {
				return false;
			}
			return !state.userInfo.attributes || !state.userInfo.attributes.email_verified;
		},
		getUserIsClientAdmin: (state) => state.userIsClientAdmin,
		getUserIsAdministrator: (state) => state.userIsAdministrator,
		getUserIsManager: (state) => state.userIsManager,
		getResetUser: (state) => state.resetUser,
		getGroups: (state) => state.groups,
		getUserData: (state) => state.userData,
		getClientUserData: (state) => state.clientUsers,
		getClientConsumerData: (state) => state.clientConsumers,
		getClientNotificationTemplateData: (state) => state.clientNotificationTemplates,
		getClients: (state) => state.clients,
		getClientInContext: (state) => state.clientInContext,
		getWorkflows: (state) => state.workflows,
		getWorkflowInContext: (state) => state.workflowInContext,
		getWorkflowMeta: (state) => state.workflowMeta,
		getTaskInContext: (state) => state.taskInContext,
		getTimelines: (state) => state.timelines,
		getTimelineInContext: (state) => state.timelineInContext,
		getTimelineTaskWidgets: (state) => state.timelineTaskWidgets,
		getNotificationTemplateInContext: (state) => state.notificationTemplateInContext,
		getErrors: (state) => state.errors,
		getAlerts: (state) => state.alerts,
		getCurrentComponent: (state) => state.currentComponent,
		getCurrentS3Bucket: (state) => state.s3_bucket,
		getWidgetIcons: () => widgetIcons
	},
	actions: {
		async login({ dispatch, commit }, { email, password }) {
			await Auth.signIn(email, password)
				.then((user) => {
					if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
						const resetUser = { user, email };
						commit('setResetUser', resetUser);
						return router.push('/reset-password').catch(() => {});
					}
					user.getSession(async (e) => {
						if (e) {
							throw e;
						}
						const userGroups = user.signInUserSession.accessToken.payload['cognito:groups'];
						Auth.currentUserInfo().then(async (currentUser) => {
							if (e) {
								throw e;
							}

							if (userGroups && userGroups.indexOf('Admin') > -1) {
								await dispatch('fetchUser');
								if (router.currentRoute.query.redirect) {
									return router.replace(router.currentRoute.query.redirect).catch(() => {});
								} else {
									router.push('/').catch(() => {});
								}
							}
							let group =
								Array.isArray(userGroups) && userGroups.length > 0
									? userGroups
									: Boolean(currentUser.attributes['custom:ClientName']) &&
									  typeof currentUser.attributes['custom:ClientName'] == 'string'
									? [currentUser.attributes['custom:ClientName']]
									: [];
							if (Array.isArray(group) && group.length > 0) {
								await dispatch('fetchUser', group);
								if (router.currentRoute.query.redirect) {
									return router.replace(router.currentRoute.query.redirect).catch(() => {});
								} else {
									router.push('/').catch(() => {});
								}
							} else {
								router.push('/').catch(() => {});
							}
						});
					});
				})
				.catch((e) => {
					commit('setErrors', 'Login Failed: ' + (e.message || 'Unknown Error'));
				});
		},
		async addUser({ commit }, userPayload) {
			let payload = {
				email: userPayload.email,
				name: userPayload.name,
				phoneNumber: userPayload?.phoneNumber || undefined
			};
			if (userPayload.isClientAdmin !== undefined) {
				payload['isClientAdmin'] = Boolean(userPayload.isClientAdmin);
			}
			if (userPayload.clientID !== undefined) {
				payload['clientID'] = userPayload.clientID;
			}

			try {
				const [apiName, path] = ['admin', '/admin/cognito/user/create'];
				const config = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: payload
				};

				const userCreated = await API.post(apiName, path, config).then((result) => {
					return (result && result.result) || false;
				});

				if (!userCreated) {
					throw new Error('Something went wrong creating the user, but no error was returned');
				}
				commit('setAlerts', 'User successfully created!');
				return true;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async fetchUser({ commit, dispatch }, clientGroup) {
			await Auth.currentAuthenticatedUser()
				.then(async (user) => {
					if (!this.getters.getUserInfo) {
						await commit(
							'setUserInfo',
							await Auth.currentUserInfo()
								.then((info) => {
									return info;
								})
								.catch(() => {
									return null;
								})
						);
					}
					await commit('authenticateUser', user);
					if (!this.getters.getIsAuthorized) {
						commit('setErrors', 'Your user is currently disabled.');
						await commit('logoutUser');
						router.push('/login').catch(() => {});
					} else if (clientGroup != null && clientGroup != 'Admin') {
						await dispatch('getClientInContext', {
							cognito_group: clientGroup
						});
					}
				})
				.catch(async (e) => {
					commit('setErrors', 'Error authenticating: ' + (e.message || 'Unknown error') + ', you have been logged out');
					await commit('logoutUser');
					router.push('/login').catch(() => {});
				});
		},
		async forgotPassword({ commit }, email) {
			return await Auth.forgotPassword(email)
				.then(() => {
					// main assumption: this worked.
					return true;
				})
				.catch((e) => {
					commit('setErrors', (e.response?.data?.message || e.message || 'Request Failed') + ', please try again');
					return false;
				});
		},
		async passwordSubmit({ commit }, { email, code, password }) {
			await Auth.forgotPasswordSubmit(email, code, password)
				.then(async () => {
					await router.push('/login').catch(() => {});
					commit('setAlerts', 'Your password has been changed, please log in.');
				})
				.catch((e) => {
					commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				});
		},
		async logout({ commit }) {
			await Auth.signOut({ global: true })
				.then(async () => {
					await commit('setAlerts', 'You have been signed out');
					router.push('/login').catch(() => {});
					commit('logoutUser');
				})
				.catch(async (e) => {
					await commit('setErrors', e.message || 'Unknown Error, please contact support');
					throw e || new Error('Unknown Logout Error');
				});
		},
		async getClientByID({ state, dispatch }, clientID) {
			// fetch clients if not already fetched
			let client = null;
			if (state.clients.length === 0 && this.getters.getUserIsAdministrator) {
				await dispatch('getClientInstances');
			}

			if (state.clients.length > 0) {
				client = state.clients.find((c) => `${c.client_id}` === `${clientID}`);
			}

			if (!client && Number.isInteger(clientID)) {
				// build payload to pull from manager api
				client = {
					client_id: clientID
				};
			}

			if (client) {
				await dispatch('getClientInContext', client);
				return this.getters.getClientInContext;
			}

			return client;
		},
		async getClientUsers({ commit }) {
			if (this.getters.getClientUserData === null) {
				try {
					// load in valid only data.
					const path = '/management/client/managers';
					const payload = {
						path: path,
						httpMethod: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: (await Auth.currentSession()).idToken.jwtToken
						},
						body: {
							clientID: this.state.clientInContext.client_id
						}
					};
					let clientUsers = await API.post(apiName, path, payload)
						.then((results) => {
							// assume empty if other things are.
							return results.result || [];
						})
						.catch(async (error) => {
							if (error.response && error.response.status == 401) {
								await commit('logoutUser');
								router.push('/login').catch(() => {});
							} else {
								throw error;
							}
						});
					await commit('setClientUserData', clientUsers);
				} catch (e) {
					commit(
						'setErrors',
						`Failed to load this client's managers: ${e.response?.data?.message ||
							e.message ||
							'Unknown Error'}. Please refresh the page.`
					);
				}
			}
			return this.getters.getClientUserData;
		},
		async getClientConsumers({ commit }, { forceFetch, sort }) {
			if (this.getters.getClientConsumerData === null || forceFetch) {
				try {
					if (!this.getters.getClientInContext || !this.getters.getClientInContext.client_id) {
						throw new Error("Cannot load Consumers - Please be in a client's context");
					}
					// load in valid only data.
					const path = '/management/client/consumer/all';
					const payload = {
						path: path,
						httpMethod: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: (await Auth.currentSession()).idToken.jwtToken
						},
						body: {
							clientID: this.getters.getClientInContext.client_id,
							sort: sort
						}
					};
					let consumers = await API.post(apiName, path, payload)
						.then((result) => {
							// assume empty if other things are.
							return result.result && Array.isArray(result.result['rows']) ? result.result['rows'] : [];
						})
						.catch(async (error) => {
							if (error.response && error.response.status == 401) {
								// since the auth header should have refreshed this, initial logout.
								await commit('logoutUser');
								router.push('/login').catch(() => {});
							} else {
								throw error;
							}
						});
					await commit('setClientConsumers', consumers);
					return true;
				} catch (e) {
					commit(
						'setErrors',
						`Failed to load this client's consumers: ${e.response?.data?.message ||
							e.message ||
							'Unknown Error'}. Please refresh the page.`
					);
					throw e;
				}
			}
			return this.getters.getClientConsumerData;
		},
		async getClientNotificationTemplates({ commit }, forceFetch) {
			if (this.getters.getClientNotificationTemplateData === null || forceFetch) {
				try {
					// load in valid only data.
					const path = '/management/notificationTemplate/get';
					const payload = {
						path: path,
						httpMethod: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: (await Auth.currentSession()).idToken.jwtToken
						},
						body: {
							clientID: this.getters.getClientInContext.client_id
						}
					};
					let clientNotificationTemplates = await API.post(apiName, path, payload)
						.then((result) => {
							// assume empty if other things are.
							return Array.isArray(result.result.rows) ? result.result.rows : [];
						})
						.catch(async (error) => {
							if (error.response && error.response.status == 401) {
								// since the auth header should have refreshed this, initial logout.
								await commit('logoutUser');
								router.push('/login').catch(() => {});
								return false;
							} else {
								throw error;
							}
						});
					await commit('setClientNotificationTemplateData', clientNotificationTemplates);
				} catch (e) {
					commit(
						'setErrors',
						`Failed to load this client's notification templates: ${e.response?.data?.message ||
							e.message ||
							'Unknown Error'}. Please refresh the page.`
					);
					throw e;
				}
			}
			return this.getters.getClientNotificationTemplateData;
		},
		async getUsersForClient({ commit }, clientID) {
			try {
				const apiName = 'admin';
				const path = '/admin/cognito/user/get';
				const payload = {
					path: path,
					httpMethod: 'GET',
					headers: {
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					}
				};

				if (this.getters.getUserIsAdministrator && clientID !== undefined) {
					payload['queryStringParameters'] = {
						clientID: clientID
					};
				}

				// get cognitoUsers as array from admin api
				let cognitoUsers = await API.get(apiName, path, payload).then((result) => {
					if (Array.isArray(result.result)) {
						return result.result;
					}
					return [];
				});

				let userData = [];
				for (let i in cognitoUsers) {
					let [name, email, group, isClientAdmin] = ['', '', '', false];
					for (let j in cognitoUsers[i].Attributes) {
						if (cognitoUsers[i].Attributes[j].Name == 'name') {
							name = cognitoUsers[i].Attributes[j].Value;
						} else if (cognitoUsers[i].Attributes[j].Name == 'email') {
							email = cognitoUsers[i].Attributes[j].Value;
						} else if (cognitoUsers[i].Attributes[j].Name == 'custom:ClientName') {
							group = cognitoUsers[i].Attributes[j].Value;
						} else if (cognitoUsers[i].Attributes[j].Name == 'custom:isClientAdmin') {
							let parsedValue =
								cognitoUsers[i].Attributes[j].Value && !isNaN(cognitoUsers[i].Attributes[j].Value)
									? parseInt(cognitoUsers[i].Attributes[j].Value.toString())
									: 0;

							if (parsedValue && parsedValue > 0) {
								isClientAdmin = true;
							}
						}
					}
					userData.push({
						name: name,
						email: email,
						group: group,
						clientAdmin: isClientAdmin,
						username: cognitoUsers[i].Username
					});
				}

				return userData;
			} catch (e) {
				commit(
					'setErrors',
					`Failed to load this client's users: ${e.response?.data?.message ||
						e.message ||
						'Unknown Error'}. Please refresh the page.`
				);
				return [];
			}
		},
		async getAllUsers({ commit }) {
			try {
				const apiName = 'admin';
				const path = '/admin/cognito/user/get';
				const payload = {
					path: path,
					httpMethod: 'GET',
					headers: {
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					}
				};

				// get cognitoUsers as array from admin api
				let cognitoUsers = await API.get(apiName, path, payload).then((result) => {
					if (Array.isArray(result.result)) {
						return result.result;
					}
					return [];
				});

				let userData = [];
				for (let i in cognitoUsers) {
					let [name, email, group, isClientAdmin] = ['', '', '', false];
					for (let j in cognitoUsers[i].Attributes) {
						if (cognitoUsers[i].Attributes[j].Name == 'name') {
							name = cognitoUsers[i].Attributes[j].Value;
						} else if (cognitoUsers[i].Attributes[j].Name == 'email') {
							email = cognitoUsers[i].Attributes[j].Value;
						} else if (cognitoUsers[i].Attributes[j].Name == 'custom:ClientName') {
							group = cognitoUsers[i].Attributes[j].Value;
						} else if (cognitoUsers[i].Attributes[j].Name == 'custom:isClientAdmin') {
							let parsedValue =
								cognitoUsers[i].Attributes[j].Value && !isNaN(cognitoUsers[i].Attributes[j].Value)
									? parseInt(cognitoUsers[i].Attributes[j].Value.toString())
									: 0;

							if (parsedValue && parsedValue > 0) {
								isClientAdmin = true;
							}
						}
					}
					userData.push({
						name: name,
						email: email,
						group: group,
						clientAdmin: isClientAdmin,
						username: cognitoUsers[i].Username
					});
				}

				commit('setUserData', userData);
			} catch (e) {
				commit(
					'setErrors',
					`Failed to load all users: ${e.response?.data?.message || e.message || 'Unknown Error'}. Please refresh the page.`
				);
				commit('setErrors', e.message);
			}
		},
		async disableUser({ commit }, managerUUID) {
			try {
				const [apiName, path] = ['admin', '/admin/cognito/user/disable'];
				const config = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						managerUUID: managerUUID
					}
				};

				const userDisabled = await API.post(apiName, path, config).then((result) => {
					return (result && result.result) || false;
				});

				if (!userDisabled) {
					throw new Error('Something went wrong disabling the user, but no error was returned');
				}
				commit('setAlerts', 'User successfully disabled');
				return true;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async getClientInstances({ commit }) {
			try {
				const apiName = 'admin';
				const path = '/admin/client/all';
				const payload = {
					path: path,
					httpMethod: 'GET',
					headers: {
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					}
				};
				const res = await API.get(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				commit('setClientInstances', res.result.rows);
			} catch (e) {
				commit(
					'setErrors',
					`Failed to load clients: ${e.response?.data?.message ||
						e.message ||
						'Unknown Error'}. Please refresh the page or try logging out/in again.`
				);
			}
		},
		async getClientInContext({ commit }, client) {
			try {
				const path = '/management/client/get';
				// (await Auth.currentSession())
				let payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					}
				};

				payload.body = client.client_id
					? { clientID: client.client_id }
					: client.cognito_group
					? { awsCognitoGroup: client.cognito_group }
					: null;

				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});

				if (res && res.result) {
					await commit('setClientInContext', res.result);
					return res.result;
				}

				return false;
			} catch (e) {
				commit('setErrors', 'Error loading client: ' + (e.message || 'Unknown Error'));
			}
		},
		async createClientInstance({ commit, dispatch }, clientPayload) {
			try {
				const apiName = 'admin';
				const path = '/admin/client/create';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						clientName: clientPayload.clientName,
						clientTheme: clientPayload.clientTheme,
						ownerName: clientPayload.ownerName,
						ownerEmail: clientPayload.ownerEmail,
						contact: clientPayload.contact
					}
				};
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				if (res) {
					dispatch('getClientInstances');
					return res.result;
				}
			} catch (e) {
				return { error: e.message };
			}
		},
		async updateClientInstance({ commit, dispatch }, clientUpdatePayload) {
			try {
				const apiName = 'admin';
				const path = '/admin/client/update';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: clientUpdatePayload
				};
				const res = await API.post(apiName, path, payload)
					.then((results) => {
						return results.result;
					})
					.catch(async (error) => {
						if (error.response && error.response.status == 401) {
							// since the auth header should have refreshed this, initial logout.
							await commit('logoutUser');
							router.push('/login').catch(() => {});
							return false;
						} else {
							throw error;
						}
					});
				await commit('setClientInContext', res);
				return res;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async regenerateClientApiKey({ commit, dispatch }) {
			if (!this.getters.getUserIsAdministrator) {
				await commit('setErrors', new Error('You do not have the permissions to do this'));
				return false;
			} else if (!this.getters.getClientInContext) {
				commit('setErrors', "Please be in a client's context");
				return;
			}
			try {
				const apiName = 'admin';
				const path = '/admin/client/apikey/regenerate';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						clientID: this.getters.getClientInContext.client_id
					}
				};
				const res = await API.post(apiName, path, payload)
					.then((results) => {
						return results.result;
					})
					.catch(async (error) => {
						if (error.response && error.response.status == 401) {
							// since the auth header should have refreshed this, initial logout.
							await commit('logoutUser');
							router.push('/login').catch(() => {});
							return false;
						} else {
							throw error;
						}
					});
				await commit('setClientInContext', res);
				dispatch('createAlerts', 'Successfully regenerated api key');
				return res;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async createClientConsumer({ commit }, consumer) {
			try {
				const path = '/management/client/consumer/create';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: consumer
				};
				const res = await API.post(apiName, path, payload)
					.then((results) => {
						return results.result;
					})
					.catch(async (error) => {
						if (error.response && error.response.status == 401) {
							// since the auth header should have refreshed this, initial logout.
							await commit('logoutUser');
							router.push('/login').catch(() => {});
							return false;
						} else {
							throw error;
						}
					});
				return res;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async updateClientConsumer({ commit, dispatch }, consumerUpdate) {
			try {
				const path = '/management/client/consumer/update';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: consumerUpdate
				};
				const res = await API.post(apiName, path, payload)
					.then((results) => {
						return results.result;
					})
					.catch(async (error) => {
						if (error.response && error.response.status == 401) {
							// since the auth header should have refreshed this, initial logout.
							await commit('logoutUser');
							router.push('/login').catch(() => {});
							return false;
						} else {
							throw error;
						}
					});
				return res;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async uploadClientLogo({ commit }, data) {
			try {
				const formData = new FormData();
				formData.append('file', data.fileData);
				formData.append('logo', 'file');
				const endpoint = (await API.endpoint('files')) + `/files/client/${data.clientID}/upload`;
				const res = await axios.post(endpoint, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					}
				});
				//todo
				return res.data.result.url;
			} catch (e) {
				commit('setErrors', e?.response?.data?.message || e?.message || 'Upload Failed, please try again');
				return false;
			}
		},
		async clientUpdateConvertboxMeta({ commit, dispatch }, meta) {
			try {
				const path = '/management/client/convertboxmeta/update';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						meta: meta
					}
				};

				if (this.getters.getUserIsAdministrator) {
					if (!this.getters.getClientInContext) {
						commit('setErrors', "Please be in a client's context");
						return;
					}
					payload.body.clientID = this.getters.getClientInContext.client_id;
				}

				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				if (res?.result !== undefined) {
					let client = this.getters.getClientInContext;
					client.client_convertbox_metum = {
						clent_id: client.client_id,
						meta: res.result
					};
					await commit('setClientInContext', client);
				}
				return true;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async uploadFile({ commit }, data) {
			try {
				const endpoint = (await API.endpoint('files')) + `/files/client/${data.clientID}/upload`;
				const res = await axios.post(endpoint, data.formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					}
				});
				return res.data.result.url;
			} catch (e) {
				if (e) return { error: e.message };
			}
		},
		async getActiveWorkflows({ commit }, inputPayload) {
			try {
				if (!inputPayload?.clientid) {
					throw new Error('clientid required');
				}
				const path = '/management/workflow/active';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						clientID: inputPayload.clientid,
						workflowIsArchivedFilter:
							inputPayload?.workflowIsArchivedFilter === undefined ? undefined : Boolean(inputPayload?.workflowIsArchivedFilter)
					}
				};
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				if (res) {
					commit('setWorkflows', res.result);
				}
				return res;
			} catch (e) {
				commit(
					'setErrors',
					`Failed to load this client's active workflows: ${e.response?.data?.message ||
						e.message ||
						'Unknown Error'}. Please refresh the page.`
				);
				return false;
			}
		},
		async getWorkflow({ commit }, { workflowUUID, workflowVersion }) {
			try {
				if (!workflowUUID || !workflowVersion) {
					throw new Error('Invalid workflow lookup, please try again');
				}
				const path = '/management/workflow/get';
				let payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						workflowUUID: workflowUUID,
						workflowVersion: workflowVersion
					}
				};
				if (this.getters.getUserIsAdministrator) {
					if (!this.getters.getClientInContext) {
						throw new Error("Please be in a client's context");
					}
					payload.body.clientID = this.getters.getClientInContext.client_id;
				}

				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				if (res?.result) {
					await this.commit('setWorkflowInContext', res.result);
					return res?.result || true;
				}
				throw new Error('Invalid workflow path, please select a different workflow');
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async getWorkflowLatest({ commit }, workflow) {
			try {
				if (!workflow.workflowLatestVersion) {
					const path = '/management/workflow/upgrade';
					const payload = {
						path: path,
						httpMethod: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: (await Auth.currentSession()).idToken.jwtToken
						},
						body: {
							clientID: workflow.client_id,
							workflowUUID: workflow.workflow_uuid
						}
					};
					const res = await API.post(apiName, path, payload).catch(async (error) => {
						if (error.response && error.response.status == 401) {
							// since the auth header should have refreshed this, initial logout.
							await commit('logoutUser');
							router.push('/login').catch(() => {});
							return false;
						} else {
							throw error;
						}
					});
					if (!res || !res.result) {
						throw new Error('Unable to resolve new version');
					}
					workflow.workflowLatestVersion = res.result;

					return this.dispatch('getWorkflowInContext', workflow);
				}
				if (workflow.workflowLatestVersion) {
					return this.dispatch('getWorkflowInContext', workflow);
				} else {
					throw new Error('Unable to resolve new version');
				}
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
			}
		},
		getWorkflowInContext({ commit }, workflow) {
			commit('setWorkflowInContext', workflow);
			return workflow;
		},
		async getWorkflowMeta({ commit }, workflow) {
			try {
				const path = '/management/workflow/getMeta';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						workflowID: workflow.workflowID,
						clientID: workflow.clientID
					}
				};
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				if (res?.result === null || typeof res?.result?.meta == 'object') {
					// typeof above will allow NULL && objects through. these are both legal.
					await commit('setWorkflowMeta', res?.result || null);
					return res?.result || true;
				}
				return false;
			} catch (e) {
				commit(
					'setErrors',
					`Failed to load this workflow's data: ${e.response?.data?.message ||
						e.message ||
						'Unknown Error'}. Please refresh the page.`
				);
			}
		},
		async getWorkflowMetadata({ commit }, workflow) {
			if (!workflow) return;

			try {
				const path = '/management/workflow/getMeta';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						workflowID: workflow.workflow_id,
						clientID: workflow.client_id
					}
				};
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				if (res?.result === null || typeof res?.result?.meta == 'object') {
					// typeof above will allow NULL && objects through. these are both legal.
					return res?.result || null;
				}
				return false;
			} catch (e) {
				commit(
					'setErrors',
					`Failed to load this workflow's data: ${e.response?.data?.message ||
						e.message ||
						'Unknown Error'}. Please refresh the page.`
				);
			}
		},
		async createNotificationTemplate({ commit }, template) {
			try {
				const path = '/management/notificationTemplate/create';
				let payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						notificationTemplateName: template.notificationTemplateName,
						notificationTemplateMeta: template.notificationTemplateMeta
					}
				};

				if (template.emailSubject) {
					payload.body.emailSubject = template.emailSubject;
				}
				if (template.clientID) {
					payload.body.clientID = template.clientID;
				}

				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});

				return res;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async updateNotificationTemplate({ commit }, template) {
			try {
				const path = '/management/notificationTemplate/update';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						notificationTemplateID: template.notificationTemplateID,
						notificationTemplateMeta: template.notificationTemplateMeta
					}
				};

				if (template.notificationTemplateName) {
					payload.body.notificationTemplateName = template.notificationTemplateName;
				}
				if (template.emailSubject) {
					payload.body.emailSubject = template.emailSubject;
				}
				if (template.clientID) {
					payload.body.clientID = template.clientID;
				}

				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return res;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async getDataBagAttributes({ commit }, clientid) {
			try {
				const path = '/management/client/info';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						clientID: clientid
					}
				};

				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return res && res.result ? res.result : false;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Failed to load Databag, please refresh page.');
				return false;
			}
		},
		async setWorkflowMeta({ commit, dispatch }, meta) {
			try {
				const path = '/management/workflow/setMeta';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						workflowID: meta.workflowID,
						workflowUUID: meta.workflowUUID,
						workflowVersion: meta.workflowVersion,
						workflowMeta: meta.workflowMeta,
						clientID: meta.clientID
					}
				};
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				commit('setWorkflowMeta', res?.result);
				return true;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async finalizeWorkflow({ commit }, meta) {
			try {
				const path = '/management/workflow/finalize';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						workflowID: meta.workflowID,
						clientID: meta.clientID
					}
				};
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return res && res.result ? res.result : false;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async archiveWorkflow({ commit }, payloadObj) {
			try {
				const path = '/management/workflow/archive';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						workflowUUID: payloadObj.workflowUUID,
						clientID: payloadObj.clientID,
						workflowIsArchived: payloadObj.workflowIsArchived === undefined ? true : Boolean(payloadObj.workflowIsArchived)
					}
				};
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return true;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async duplicateWorkflow({ dispatch }, workflow) {
			const latestWorkflow = await dispatch('getWorkflow', {
				workflowUUID: workflow.workflow_uuid,
				workflowVersion: workflow.latest_version
			});

			let result = await dispatch('createWorkflow', {
				...latestWorkflow,
				clientID: latestWorkflow.client_id,
				workflowName: `Copy of ${latestWorkflow.workflow_name}`,
				managerID: latestWorkflow.manager_id
			});

			if (!result) return result;

			const workflowMeta = await dispatch('getWorkflowMeta', {
				...latestWorkflow,
				workflowID: latestWorkflow.workflow_id,
				clientID: latestWorkflow.client_id
			});

			await dispatch('setWorkflowMeta', {
				workflowID: result.workflow_id,
				workflowUUID: result.workflow_uuid,
				workflowVersion: result.workflow_version,
				clientID: result.client_id,
				workflowMeta: workflowMeta.meta
			});

			return true;
		},
		async createWorkflow({ commit }, workflowPayload) {
			try {
				const path = '/management/workflow/create';
				const payload = {
					path: '/management/workflow/create',
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: workflowPayload
				};
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return res && res.result ? res.result : false;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async updateWorkflow({ commit }, workflowPayload) {
			try {
				const path = '/management/workflow/update';
				const payload = {
					path: '/management/workflow/update',
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						clientID: workflowPayload.clientID,
						workflowID: workflowPayload.workflowID,
						workflowUUID: workflowPayload.workflowUUID,
						workflowVersion: workflowPayload.workflowVersion,
						workflowName: workflowPayload.workflowName,
						workflowTimelineAftermathUUID: workflowPayload.workflowTimelineAftermathUUID
					}
				};
				// NULL Or a valid object (null is typeof object)
				if (typeof workflowPayload.workflowTimelineOffsetMeta == 'object') {
					payload.body.workflowTimelineOffsetMeta = workflowPayload.workflowTimelineOffsetMeta;
				}
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return res && res.result ? res.result : false;
			} catch (e) {
				commit('setErrors', e.response?.data?.message || e.message || 'Request Failed, please try again');
				return false;
			}
		},
		async getWorkflowTaskGroups({ commit }, workflow) {
			try {
				const path = '/management/workflow/data';
				let payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						workflowID: workflow.workflowID
					}
				};

				if (this.getters.getUserIsAdministrator) {
					if (!this.getters.getClientInContext) {
						commit('setErrors', "Please be in a client's context");
						return;
					}
					payload.body.clientID = this.getters.getClientInContext.client_id;
				}
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return res && res.result ? res.result : false;
			} catch (e) {
				commit(
					'setErrors',
					`Failed to load this workflow's tasks: ${e.response?.data?.message ||
						e.message ||
						'Unknown Error'}. Please refresh the page.`
				);
				return false;
			}
		},
		async getWorkflowTasks({ commit }, group) {
			try {
				const path = '/management/workflow/taskgroup/task/get';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						workflowID: group.workflowID,
						taskGroupID: group.taskGroupID
					}
				};
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return res && res.result ? res.result : false;
			} catch (e) {
				commit(
					'setErrors',
					`Failed to load this workflow's tasks: ${e.response?.data?.message ||
						e.message ||
						'Unknown Error'}. Please refresh the page.`
				);
				return false;
			}
		},
		/* GET notifications*/
		async getUserTaskNotifications({ commit }, data) {
			try {
				const path = '/management/client/manager/timeline/task/active/get';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						clientID: data.clientID,
						managerID: data.managerID
					}
				};
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return res && res.result && res.result.rows ? res.result.rows : false;
			} catch (e) {
				return {
					errors: e.message
				};
			}
		},
		async timelineNotifyManager({ commit }, notification) {
			try {
				const path = '/management/client/manager/timeline/task/active/notify';
				let payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						timelineTaskGroupTaskID: notification.timelineTaskGroupTaskID,
						timelineID: notification.timelineID,
						consumerID: notification.consumerID
					}
				};

				if (this.getters.getUserIsAdministrator) {
					if (!this.getters.getClientInContext) {
						commit('setErrors', "Please be in a client's context");
						return;
					}
					payload.body.clientID = this.getters.getClientInContext.client_id;
				}
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				if (res) {
					commit('setAlerts', 'Task Manager Notified');
				} else {
					commit('setErrors', 'Task Manager was not notified due to errors, please try again');
				}
				return res;
			} catch (e) {
				return {
					errors: e.message
				};
			}
		},
		/* GET timelines,
			UPDATE timelines,
			GET timeline tasks,
		*/
		async initializeTimeline({ commit }, timeline) {
			try {
				const path = '/management/timeline/create';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						clientID: timeline.clientID,
						workflowUUID: timeline.workflowUUID,
						consumerEmail: timeline.consumerEmail,
						consumerMeta: timeline.consumerMeta
					}
				};
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				if (res?.result?.timeline_id) {
					return res.result?.timeline_id;
				}
				return false;
			} catch (e) {
				return {
					errors: e.response?.data?.message || e.message
				};
			}
		},
		async getAllTimelines({ commit }) {
			try {
				const path = '/management/timelines/get';
				let payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						includeConsumerData: true
					}
				};
				if (this.getters.getUserIsAdministrator) {
					if (!this.getters.getClientInContext || !this.getters.getClientInContext.client_id) {
						commit('setTimelines', []);
						return {};
					}
					payload.body['clientID'] = this.getters.getClientInContext.client_id;
				}
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				if (res && res.result && Array.isArray(res.result.rows)) {
					commit('setTimelines', res.result.rows);
					return res;
				} else {
					commit('setTimelines', []);
					return false;
				}
			} catch (e) {
				return false;
			}
		},
		async getTimeline({ commit }, id) {
			try {
				const path = '/management/timeline/get';
				let payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						timelineID: id
					}
				};

				if (this.getters.getUserIsAdministrator) {
					if (!this.getters.getClientInContext) {
						commit('setErrors', "Please be in a client's context");
						return;
					}
					payload.body.clientID = this.getters.getClientInContext.client_id;
				}
				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				if (res && res.result) {
					await commit('setTimelineInContext', res.result);
					return res.result;
				}
				return false;
			} catch (e) {
				return false;
			}
		},
		async getTimelineTask({ commit }, timeline) {
			try {
				const path = '/management/timeline/task/get';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						timelineID: timeline.timelineID,
						consumerID: timeline.consumerID,
						timelineTaskGroupTaskUUID: timeline.taskGroupTaskID
					}
				};

				if (this.getters.getUserIsAdministrator) {
					if (!this.getters.getClientInContext) {
						commit('setErrors', "Please be in a client's context");
						return false;
					}
					payload.body.clientID = this.getters.getClientInContext.client_id;
				}

				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return res && res.result ? res.result : false;
			} catch (e) {
				commit('setErrors', e);
				return false;
			}
		},
		async updateTimeline({ commit }, timeline) {
			try {
				const path = '/management/timeline/update';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						timelineID: timeline.timelineID
					}
				};

				// only pass updated things if its there.
				if (timeline.actionID !== undefined && timeline.actionID !== null) {
					payload.body.actionID = timeline.actionID;
				}

				if (timeline.inProgress !== undefined && timeline.inProgress !== null) {
					payload.body.inProgress = timeline.inProgress;
				}

				if (timeline.unsubscribed !== undefined && timeline.unsubscribed !== null) {
					payload.body.unsubscribed = timeline.unsubscribed;
				}

				if (this.getters.getUserIsAdministrator) {
					if (!this.getters.getClientInContext) {
						commit('setErrors', "Please be in a client's context");
						return;
					}
					payload.body.clientID = this.getters.getClientInContext.client_id;
				}

				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return { success: res ? true : false };
			} catch (e) {
				return {
					success: false,
					message: (e.response && e.response.data ? e.response.data.message : '') || e.message
				};
			}
		},
		async cancelTimeline({ commit }, timeline) {
			try {
				const path = '/management/timeline/cancel';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						timelineID: timeline.timelineID
					}
				};

				if (this.getters.getUserIsAdministrator) {
					if (!this.getters.getClientInContext) {
						commit('setErrors', "Please be in a client's context");
						return;
					}
					payload.body.clientID = this.getters.getClientInContext.client_id;
				}

				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return { success: res ? true : false };
			} catch (e) {
				return {
					success: false,
					message: e?.response?.data?.message || e?.message || 'Unknown Error'
				};
			}
		},
		async updateInputMeta({ commit }, meta) {
			try {
				const path = '/management/timeline/task/update';
				const payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						timelineID: meta.timelineID,
						consumerID: meta.consumerID,
						timelineTaskGroupTaskID: meta.timelineTaskGroupTaskID,
						timelineTaskGroupTaskUUID: meta.timelineTaskGroupTaskUUID,
						inputMeta: meta.inputMeta
					}
				};
				if (meta.completed) {
					payload.body.completed = true;
				}

				if (this.getters.getUserIsAdministrator) {
					if (!this.getters.getClientInContext) {
						commit('setErrors', "Please be in a client's context");
						return;
					}
					payload.body.clientID = this.getters.getClientInContext.client_id;
				}

				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return res ? true : false;
			} catch (e) {
				commit('setErrors', e);
				return false;
			}
		},
		async getClientLogo({ state }, clientID) {
			try {
				const logo = `https://${state.s3_bucket}.s3.amazonaws.com/${clientID}/logofile.png`;

				await axios.get(logo);

				return logo;
			} catch (e) {
				return null;
			}
		},
		async getConsumer({ commit }, id) {
			try {
				const path = '/management/client/consumer/get';
				let payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: {
						consumerID: id
					}
				};

				if (this.getters.getUserIsAdministrator) {
					if (!this.getters.getClientInContext) {
						commit('setErrors', "Please be in a client's context");
						return;
					}
					payload.body.clientID = this.getters.getClientInContext.client_id;
				}

				const res = await API.post('admin', path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return res && res.result ? res.result : false;
			} catch (e) {
				return false;
			}
		},
		async getConsumerTimelineActivity({ commit }, id) {
			try {
				const body = {
					consumerID: !Number.isNaN(id) && Number.isInteger(+id) ? id : undefined,
					clientID: undefined
				};
				if (this.getters.getUserIsAdministrator) {
					if (!this.getters.getClientInContext) {
						commit('setErrors', "Please be in a client's context");
						return;
					}

					body.clientID = this.getters.getClientInContext.client_id;
				}

				const path = '/management/timelines/activity';

				let payload = {
					path: path,
					httpMethod: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: (await Auth.currentSession()).idToken.jwtToken
					},
					body: body
				};

				const res = await API.post(apiName, path, payload).catch(async (error) => {
					if (error.response && error.response.status == 401) {
						// since the auth header should have refreshed this, initial logout.
						await commit('logoutUser');
						router.push('/login').catch(() => {});
						return false;
					} else {
						throw error;
					}
				});
				return res && res.result ? res.result : false;
			} catch (e) {
				return false;
			}
		},
		/*		GET tasks,
				CREATE a task,
		*/
		setTaskInContext({ commit }, task) {
			commit('setTaskInContext', task);
		},
		/* alerts and navigation */
		createErrors({ commit }, error) {
			// force to array - this resets errors.
			if (error != null || error != '') {
				commit('setErrors', Array.isArray(error) ? error : [error]);
			}
		},
		createAlerts({ commit }, alert) {
			if (alert != null || alert != '') {
				commit('setAlerts', alert);
			}
		},
		currentComponentSet({ commit }, component) {
			if (component != null || component != '') {
				commit('setCurrentComponent', component);
			}
		}
	},
	mutations: {
		authenticateUser: (state, user) => {
			// localStorage.setItem('token', user.signInUserSession.idToken.jwtToken);

			state.user = user;
			state.userIsManager = false;
			state.userIsClientAdmin = false;
			state.userIsAdministrator = false;

			// attempt to set groups
			state.groups = user.signInUserSession.accessToken.payload['cognito:groups'] || null;
			// if state.groups is a single group name for some reason. Possibly unneeded, but trusting AWS Cognito seems sketchy.
			if (state.groups && typeof state.groups == 'string' && !Array.isArray(state.groups)) {
				state.groups = [state.groups];
			}

			// if no groups are assigned, consider this a bad user that was not deactivated.
			// Groups are the crux of the system
			if (Array.isArray(state.groups) && state.groups.length > 0) {
				// check for admin group
				if (state.groups.indexOf('Admin') > -1) {
					state.userIsAdministrator = true;
				} else if (user.attributes['custom:isClientAdmin'] && !isNaN(user.attributes['custom:isClientAdmin'])) {
					// if userattributes exist, & not NAN, attempt to read clientAdmin
					let parsedState = parseInt(user.attributes['custom:isClientAdmin'].toString());
					if (parsedState && parsedState > 0) {
						// should be a 1 -- but all things are strings and '0' is false, so parse int needs to trigger and >0 is the truthiness statement
						state.userIsClientAdmin = true;
					}
				}

				//if not an administrator or an admin at this point, must be a manager
				if (!state.userIsAdministrator && !state.userIsClientAdmin) {
					state.userIsManager = true;
				}
			}

			// authorized only if binned correctly
			state.authorized = state.userIsManager || state.userIsClientAdmin || state.userIsAdministrator;
		},
		setResetUser: (state, user) => {
			state.resetUser = user;
		},
		setUserInfo: (state, userInfo) => {
			state.userInfo = userInfo;
		},
		setAlerts: (state, alert) => {
			if (state.alerts.length > 0 && Array.isArray(state.alerts)) {
				state.alerts = [];
			}
			if (alert) {
				if (alert.length > 1 && Array.isArray(alert)) {
					for (let i in alert) {
						state.alerts.push(alert[i]);
					}
				} else {
					state.alerts.unshift(alert);
				}
			}
		},
		setClientConsumers: (state, data) => {
			state.clientConsumers = data;
		},
		setUserData: (state, data) => {
			state.userData = data;
		},
		setClientUserData: (state, data) => {
			state.clientUsers = data;
		},
		setClientNotificationTemplateData: (state, data) => {
			state.clientNotificationTemplates = data;
		},
		setClientInContext: (state, client) => {
			if (client != null) {
				client.logo = '';
				if (state.s3_bucket) {
					client.logo = `https://${state.s3_bucket}.s3.amazonaws.com/${client.client_id}/logofile.png`;
				}
				if (client.logo) {
					axios
						.get(client.logo)
						.then(() => {
							return true;
						})
						.catch((e) => {
							client.logo = null;
							return false;
						});
				}
			}
			state.clientInContext = client;
			state.clientUsers = null;
			state.clientNotificationTemplates = null;
			state.clientConsumers = null;
		},
		setClientInstances: (state, clients) => {
			state.clients = clients;
		},
		setWorkflows: (state, workflows) => {
			state.workflows = workflows;
		},
		setWorkflowInContext: (state, workflow) => {
			state.workflowInContext = workflow;
		},
		setWorkflowMeta: (state, workflowMeta) => {
			let usedWorkflowID = workflowMeta?.workflow_id || state?.workflowInContext?.workflow_id || undefined;
			if (usedWorkflowID === undefined) {
				throw new Error('Workflow Metadata Malformed');
			}
			state.workflowMeta = {
				meta: { groups: [] },
				workflow_id: usedWorkflowID
			};
			if (Array.isArray(workflowMeta?.meta?.groups)) {
				state.workflowMeta.meta.groups = workflowMeta.meta.groups;
			}
		},
		setTimelines: (state, timelines) => {
			state.timelines = timelines;
		},
		setTaskInContext: (state, task) => {
			state.taskInContext = task;
		},
		setTimelineInContext: (state, timeline) => {
			state.timelineInContext = timeline;
		},
		setTimelineTaskWidgets: (state, taskWidgets) => {
			state.timelineTaskWidgets = taskWidgets;
		},
		setNotificationTemplateInContext: (state, template) => {
			state.notificationTemplateInContext = template;
		},
		setCurrentComponent: (state, component) => {
			state.currentComponent = component;
		},
		setErrors: (state, errors) => {
			if (!state.errors || (state.errors.length > 0 && Array.isArray(state.errors))) {
				state.errors = [];
			}
			if (errors) {
				if (Array.isArray(errors)) {
					state.errors.push(...errors);
				} else {
					state.errors.unshift(errors);
				}
			}
		},
		setResetStoreValues: (state) => {
			[state.workflowInContext, state.timelineInContext, state.taskInContext] = [null, null, null];
			[state.currentComponent, state.workflowMeta] = ['', null];
			sessionStorage.removeItem('taskIdx', 'groupIdx');
		},
		setResetClientInContext: (state) => {
			state.clientInContext = null;
		},
		setResetBaselineComponent: (state) => {
			state.resetUser = null;
			state.workflowInContext = null;
			state.workflowMeta = null;
			state.taskInContext = null;
			state.timelineInContext = null;
			state.timelineTaskWidgets = null;
			state.notificationTemplateInContext = null;
			state.clientConsumers = null;
		},
		logoutUser: (state) => {
			sessionStorage.clear();
			localStorage.clear();
			Auth.signOut();

			state.authorized = false;
			state.user = null;
			state.userInfo = null;
			state.userIsClientAdmin = false;
			state.userIsAdministrator = false;
			state.userIsManager = false;
			state.resetUser = null;
			state.userData = null;
			state.groups = null;
			state.clients = [];
			state.clientInContext = null;
			state.clientUsers = null;
			state.clientConsumers = null;
			state.clientNotificationTemplates = null;
			state.workflows = [];
			state.workflowInContext = null;
			state.workflowMeta = null;
			state.taskInContext = null;
			state.timelines = [];
			state.timelineInContext = null;
			state.errors = [];
			state.alerts = [];
			state.currentComponent = '';
			state.s3_bucket = null;
			state.s3_region = null;
			state.timelineTaskWidgets = null;
			state.notificationTemplateInContext = null;
		}
	}
});
