<template>
	<div>
		<b-modal v-model="showAlertModal" v-if="alert.length > 0" @ok="resetModals" title="Alert" size="sm" buttonSize="sm" ok-only ok-variant="success">
			<p v-for="(msg, i) in alert" :key="i">{{ msg }}</p>
		</b-modal>
		<b-modal v-model="showErrorModal" v-if="errorMsg.length > 0" @ok="resetModals" title="Error" size="lg" buttonSize="sm" ok-only ok-variant="danger">
			<p v-for="error in errorMsg" :key="error.id">{{ error }}</p>
		</b-modal>
	</div>
</template>

<script>

	export default {
		data() {
			return {
				alert: [],
				errorMsg: [],
				showAlertModal: false,
				showErrorModal: false,
			};
		},
		created() {
			this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
				this.$store.state.alerts = [];
				this.$store.state.errors = [];
			});

			this.$store.watch(
				state => state.alerts,
				alerts => {
					if (alerts != [] && alerts.length > 0) {
						if (alerts.length > 1) {
							this.alert = alerts;
							this.$store.state.alerts = [];
							return this.showAlertModal = true;
						}
						this.$bvToast.toast(`${alerts[0]}`, {
							title: 'Alert',
							variant: 'success',
							autoHideDelay: 7500,
						});
						this.$store.state.alerts = [];
					}
				}
			);

			this.$store.watch(
				state => state.errors,
				errors => {
					if (errors != [] && errors.length > 0) {
						if (errors.length > 1) {
							this.errorMsg = errors;
							this.$store.state.errors = [];
							return this.showErrorModal = true;
						}
						this.$bvToast.toast(`${errors[0]}`, {
							title: 'Error',
							variant: 'danger',
							autoHideDelay: 20000,
						});
						this.$store.state.errors = [];
					}
				}
			);
		},
		methods: {
			resetModals() {
				this.$store.state.alerts = [];
			}
		}
	};
</script>
