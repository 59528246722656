var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticClass:"text-center mx-auto"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning"}})],1):_vm._e(),(!_vm.loading && _vm.getClientInContext)?_c('div',{staticClass:"manager-dashboard-container"},[_c('div',{staticClass:"header-row"},[(_vm.client != null)?_c('h3',[_vm._v("Your Timelines")]):_vm._e(),_c('hr'),_c('b-card',{staticClass:"w-100 h-100"},[_c('b-container',{staticClass:"w-100 text-center"},[(
              _vm.getClientInContext &&
                _vm.getClientInContext.stats &&
                _vm.getClientInContext.stats.timelines
            )?_c('b-row',{staticClass:"mx-auto"},[_c('b-col',{staticClass:"stats-container"},[_c('strong',[_vm._v("Active Workflows")]),_c('div',[_c('b',[_vm._v(" "+_vm._s(_vm.client.stats && _vm.client.stats.active_workflows ? _vm.client.stats.active_workflows : 0)+" ")]),_c('i',{staticClass:"fas fa-stream"})])]),_c('b-col',{staticClass:"stats-container"},[_c('strong',[_vm._v("Finalized Wokflows:")]),_c('div',[_vm._v(" "+_vm._s(_vm.client.stats && _vm.client.stats.finalized_workflows ? _vm.client.stats.finalized_workflows : 0)+" "),_c('i',{staticClass:"fas fa-stream"})])]),_c('b-col',{staticClass:"stats-container"},[_c('strong',[_vm._v("Active Timelines:")]),_c('div',[_vm._v(" "+_vm._s(_vm.client.stats && _vm.client.stats.active_timelines ? _vm.client.stats.active_timelines : 0)+" "),_c('i',{staticClass:"fas fa-history"})])]),_c('b-col',{staticClass:"stats-container"},[_c('strong',[_vm._v("Completed Timelines:")]),_c('div',[_vm._v(" "+_vm._s(_vm.client.stats && _vm.client.stats.completed_timelines ? _vm.client.stats.completed_timelines : 0)+" "),_c('i',{staticClass:"fas fa-history"})])])],1):_c('b-row',[_c('p',[_vm._v("You do not have any timelines")])]),_c('b-row',{staticClass:"d-flex w-100"},[_c('div',{staticClass:"d-flex m-auto justify-content-around w-100"},[(
                  _vm.getClientInContext &&
                    _vm.getClientInContext.stats &&
                    _vm.getClientInContext.stats.timelines
                )?[_c('router-link',{attrs:{"to":{
                    name: 'ClientTimelines',
                    params: { clientID: _vm.getClientInContext.client_id },
                  }}},[_c('button',{staticClass:"btn-blue"},[_vm._v(" View Timelines ")])])]:_vm._e(),_c('router-link',{attrs:{"to":{
                  name: 'ClientWorkflowOverview',
                  params: { clientID: _vm.getClientInContext.client_id },
                }}},[_c('button',{staticClass:"btn-outline-blue"},[_vm._v("View Workflows")])]),_c('router-link',{attrs:{"to":{
                  name: 'ClientConsumers',
                  params: { clientID: _vm.getClientInContext.client_id },
                }}},[_c('button',{staticClass:"btn-blue"},[_vm._v("View Consumers")])])],2)])],1)],1)],1),_c('div',{staticClass:"header-row"},[_c('h3',[_vm._v("What You've Missed")]),_c('hr'),_c('b-card',{staticClass:"notification-center h-100"},[(_vm.notifications != null)?_c('div',[_c('p',[_vm._v(" You have "),_c('strong',[_vm._v(_vm._s(_vm.notifications.length))]),_vm._v(" new notification(s) ")]),_vm._l((_vm.notifications),function(notification,i){return _c('div',{key:i,staticClass:"notification-item"},[_c('p',{staticClass:"p-0 m-0"},[_c('strong',[_vm._v("New Task:")]),_vm._v(" "+_vm._s(notification.timeline_task_group_task_uuid)+"! ")]),_c('div',{staticClass:"notification-footer font-italic"},[_c('p',[_vm._v(" Updated: "+_vm._s(_vm.convertDate(notification.last_update_datetime) || null)+" ")]),_c('a',{staticClass:"ml-1",on:{"click":function($event){return _vm.startTask(notification)}}},[_vm._v("Start Task "),_c('i',{staticClass:"fas fa-play"})])])])})],2):_vm._e(),(_vm.notifications == null)?_c('div',[_c('p',[_vm._v("You have "),_c('strong',[_vm._v("0")]),_vm._v(" new notifications")])]):_vm._e()])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }