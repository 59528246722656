<template>
	<div>
		<draggable class="widget-toolbar-container" v-model="widgets" :group="{name: 'widgets', pull: 'clone', put: false}" :clone="onClone">
				<div v-for="widget in widgets" :key="widget.id">
					<p><i :class="widget.icon"></i>{{ widget.text }}</p>
				</div>
		</draggable>
	</div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
	props: ['templateMeta'],
	components: { draggable },
	data() {
		return {
			element: '',
			globalID: 0,
			widgets: [
				{ text: 'Text', icon: 'fas fa-font', component: 'TextEdit' },
				{ text: 'Photo', icon: 'far fa-image', component: 'Photo' },
				{ text: 'Video', icon: 'fas fa-video', component: 'Video' },
			],
		};
	},
	methods : {
		onChange(e) {
			this.element = e.draggedContext.element;
		},
		onClone(element) {
			let containsElement = this.templateMeta.find(item => item.component === element.component);
			if (!containsElement || containsElement == null) {
				this.element = element;
				return {
					id: this.globalID++,
					type: this.element.text.toLowerCase(),
					component: this.element.component
				};
			} else {
				this.$store.dispatch('createErrors', `Your template can only have one ${element.text} widget.`);
			}
		}
	}
};
</script>
