<template>
  <div>
    <NavBar />
    <Alerts />
    <router-view />
    <footer>
      <p>&#169;{{ currentYear }} MDME</p>
    </footer>
  </div>
</template>

<script>
import NavBar from "@/components/nav/NavBar.vue";
import Alerts from "@/components/Alerts";

export default {
  name: "app",
  components: { NavBar, Alerts },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
<style>
@import "./assets/css/main.css";
</style>
