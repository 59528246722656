<template>
  <div>
    <div class="text-center mx-auto" v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div
      class="manager-dashboard-container"
      v-if="!loading && getClientInContext"
    >
      <div class="header-row">
        <h3 v-if="client != null">Your Timelines</h3>
        <hr />
        <b-card class="w-100 h-100">
          <b-container class="w-100 text-center">
            <b-row
              v-if="
                getClientInContext &&
                  getClientInContext.stats &&
                  getClientInContext.stats.timelines
              "
              class="mx-auto"
            >
              <b-col class="stats-container">
                <strong>Active Workflows</strong>
                <div>
                  <b>
                    {{
                      client.stats && client.stats.active_workflows
                        ? client.stats.active_workflows
                        : 0
                    }}
                  </b>
                  <i class="fas fa-stream"></i>
                </div>
              </b-col>
              <b-col class="stats-container">
                <strong>Finalized Wokflows:</strong>
                <div>
                  {{
                    client.stats && client.stats.finalized_workflows
                      ? client.stats.finalized_workflows
                      : 0
                  }}
                  <i class="fas fa-stream"></i>
                </div>
              </b-col>
              <b-col class="stats-container">
                <strong>Active Timelines:</strong>
                <div>
                  {{
                    client.stats && client.stats.active_timelines
                      ? client.stats.active_timelines
                      : 0
                  }}
                  <i class="fas fa-history"></i>
                </div>
              </b-col>
              <b-col class="stats-container">
                <strong>Completed Timelines:</strong>
                <div>
                  {{
                    client.stats && client.stats.completed_timelines
                      ? client.stats.completed_timelines
                      : 0
                  }}
                  <i class="fas fa-history"></i>
                </div>
              </b-col>
            </b-row>
            <b-row v-else>
              <p>You do not have any timelines</p>
            </b-row>
            <b-row class="d-flex w-100">
              <div class="d-flex m-auto justify-content-around w-100">
                <template
                  v-if="
                    getClientInContext &&
                      getClientInContext.stats &&
                      getClientInContext.stats.timelines
                  "
                >
                  <router-link
                    :to="{
                      name: 'ClientTimelines',
                      params: { clientID: getClientInContext.client_id },
                    }"
                  >
                    <button class="btn-blue">
                      View Timelines
                    </button>
                  </router-link>
                </template>
                <router-link
                  :to="{
                    name: 'ClientWorkflowOverview',
                    params: { clientID: getClientInContext.client_id },
                  }"
                >
                  <button class="btn-outline-blue">View Workflows</button>
                </router-link>
                <router-link
                  :to="{
                    name: 'ClientConsumers',
                    params: { clientID: getClientInContext.client_id },
                  }"
                >
                  <button class="btn-blue">View Consumers</button>
                </router-link>
              </div>
            </b-row>
          </b-container>
        </b-card>
      </div>
      <div class="header-row">
        <h3>What You've Missed</h3>
        <hr />
        <b-card class="notification-center h-100">
          <div v-if="notifications != null">
            <p>
              You have <strong>{{ notifications.length }}</strong> new
              notification(s)
            </p>
            <div
              v-for="(notification, i) in notifications"
              :key="i"
              class="notification-item"
            >
              <p class="p-0 m-0">
                <strong>New Task:</strong>
                {{ notification.timeline_task_group_task_uuid }}!
              </p>
              <div class="notification-footer font-italic">
                <p>
                  Updated:
                  {{ convertDate(notification.last_update_datetime) || null }}
                </p>
                <a class="ml-1" @click="startTask(notification)"
                  >Start Task <i class="fas fa-play"></i
                ></a>
              </div>
            </div>
          </div>
          <div v-if="notifications == null">
            <p>You have <strong>0</strong> new notifications</p>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      notifications: null,
      timelines: [],
      activeTimelines: null,
      completedTimelines: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      "getClientInContext",
      "getUser",
      "getTimelines",
      "getUserIsClientAdmin",
    ]),
    client() {
      return this.getClientInContext;
    },
  },
  async mounted() {
    this.loading = true;
    try {
      const group = this.getUser.signInUserSession.accessToken.payload[
        "cognito:groups"
      ];
      await this.$store.dispatch("getClientInContext", { cognito_user: group });
      if (this.getClientInContext && this.getUser) {
        let notifications = await this.$store.dispatch(
          "getUserTaskNotifications",
          {
            clientID: this.getClientInContext.client_id,
            managerID: this.getUser.attributes.sub,
          }
        );
        if (notifications) {
          this.notifications = notifications;
        }
      }
      await this.$bus.$emit("breadcrumbData", []);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async startTask(task) {
      let timeline = await this.$store.dispatch("getTimelineTask", {
        timelineID: task.timeline_id,
        consumerID: task.consumer_id,
        taskGroupTaskID: task.timeline_task_group_task_uuid,
      });

      if (timeline) {
        this.$router
          .push({
            name: "ClientManagerTimelineTask",
            params: {
              clientID: this.getClientInContext.client_id,
              timelineID: task.timeline_id,
              taskGroupTaskID: task.timeline_task_group_task_uuid,
            },
          })
          .catch(() => {});
      }
    },
    convertDate(date) {
      const strDate = moment(date).format("MM/DD/YYYY h:mm a");
      return strDate;
    },
  },
};
</script>
