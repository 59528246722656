<template>
  <div class="reporting-screen-container">
    <h3>Reporting</h3>
    <div
      class="d-flex justify-content-center row text"
      v-if="reportingClient == null"
    >
      <b-card
        v-for="(client, i) in clients"
        :key="i"
        class="col-3 m-2 text-left"
      >
        <p><strong>Name:</strong> {{ client.client_name }}</p>
        <p><strong>Workflows:</strong> {{ client.workflow_count }}</p>
        <p>
          <strong>Timelines:</strong>
          {{ client.timeline_count ? client.timeline_count : 0 }}
        </p>
        <button class="btn-blue w-100" @click.prevent="getClientReport(i)">
          Click to view report
        </button>
      </b-card>
    </div>
    <div class="reporting-info" v-if="reportingClient != null">
      <div class="row text-left m-4">
        <a @click.prevent="reportingClient = null" class="back-btn"
          ><i class="far fa-arrow-alt-circle-left"></i> Back to task overview</a
        >
      </div>
      <div class="row">
        <div class="col">
          <h2>Timeline Statistics</h2>
          <hr />
          <p><strong>Completed:</strong> {{ completed }}</p>
          <p><strong>Average Duration</strong> {{ duration }}</p>
          <p><strong></strong></p>
        </div>
        <div class="col">
          <h2>Attrition Percentages</h2>
          <hr />
          <p><strong>Completed:</strong> {{ completed }}</p>
          <p><strong>Average Duration:</strong> {{ duration }}</p>
          <p><strong></strong></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      clients: null,
      reportingClient: null,
      completed: 0,
      duration: "00:00",
    };
  },
  async mounted() {
    await this.$store.dispatch("getClientInstances");
    this.clients = this.getClients;
  },
  computed: {
    ...mapGetters(["getClients"]),
  },
  methods: {
    getClientReport(i) {
      this.reportingClient = this.clients[i];
    },
  },
};
</script>
<style scoped>
.reporting-info {
  text-align: left;
  width: 95%;
  margin: 0 auto;
}
.reporting-info h2 {
  font-size: 20px;
  font-weight: 100;
}
.row {
  width: 100%;
  margin: 0 auto;
}
</style>
