<template>
  <div class="text-left m-2">
    <a class="back-btn" @click="goBack">
      <i class="far fa-arrow-alt-circle-left"></i> Back
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    hasHistory() {
      return (
        (document.referrer.includes(window.location.origin) ||
          window.location.origin.includes(document.referrer)) &&
        window.history.length > 2
      );
    },
    goBack() {
      if (this.hasHistory()) this.$router.go(-1);
      else this.$router.push("/");
    },
  },
};
</script>
